import React from 'react'
import AboutSewction from '../sections/Aboutpages/AboutSewction'
import { FaUser } from 'react-icons/fa'; // Example icon library




function About() {
  return (
   <div className="">
       <AboutSewction/>
   </div>
  )
}

export default About