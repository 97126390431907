import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import Faq from './Faq';
import AviationFaq from './AviationFaq';
import { assets } from '../../assets/assets';


const aviationInsuranceFeatures = [
    {
      title: "Aircraft Hull Insurance",
      description: "Covers physical damage to the insured aircraft due to accidents, fire, theft, or natural disasters.",
    },
    {
      title: "Passenger Liability Coverage",
      description: "Provides financial compensation for injuries or fatalities to passengers during a flight.",
    },
    {
      title: "Third-Party Liability",
      description: "Protects against legal liabilities for property damage or injuries caused to third parties by the insured aircraft.",
    },
    {
      title: "Crew Member Coverage",
      description: "Offers financial protection for pilots and crew members in case of injuries or loss of life.",
    },
    {
      title: "Ground Risk Insurance",
      description: "Covers damages to the aircraft while it is parked, being serviced, or during ground transit.",
    },
    {
      title: "Loss of License Insurance",
      description: "Provides financial assistance to pilots who lose their license due to medical conditions or other factors.",
    },
    {
      title: "War and Terrorism Coverage",
      description: "Protects against risks arising from acts of war, terrorism, or sabotage.",
    },
  ];

const whyAviationInsuranceIsEssential = [
    {
      title: "Mitigating Financial Risks",
      description: "The aviation industry involves high-value assets and operations. Aviation Insurance provides financial protection against significant losses.",
    },
    {
      title: "Regulatory Compliance",
      description: "Most countries mandate aviation insurance for aircraft operators to meet legal and regulatory requirements.",
    },
    {
      title: "Passenger and Crew Safety",
      description: "Ensures compensation and support for passengers and crew in the event of accidents.",
    },
    {
      title: "Reputation Management",
      description: "Protects the reputation of aviation companies by covering liabilities and ensuring quick resolution of claims.",
    },
    {
      title: "Peace of Mind",
      description: "Allows aviation operators to focus on operations without worrying about unforeseen risks.",
    },
  ];

const aviationInsuranceCoverageTypes = [
    {
      title: "Hull All-Risk Coverage",
      description: "Comprehensive protection against all physical damage risks to the aircraft, whether in flight, on the ground, or in storage.",
    },
    {
      title: "Public Liability Insurance",
      description: "Covers third-party claims for property damage or injuries caused by the insured aircraft.",
    },
    {
      title: "Passenger Liability Insurance",
      description: "Provides compensation for injuries or fatalities to passengers onboard the insured aircraft.",
    },
    {
      title: "Crew Member Coverage",
      description: "Financial protection for injuries or loss of life among pilots and crew members.",
    },
    {
      title: "Ground Handling Coverage",
      description: "Covers risks associated with aircraft ground handling, including fuelling, towing, and maintenance.",
    },
    {
      title: "Airport Liability Insurance",
      description: "Protection for airports against liabilities arising from accidents, property damage, or third-party injuries.",
    },
    {
      title: "Hangar Keeper’s Liability Insurance",
      description: "Covers damages to aircraft under the care of hangar operators or maintenance facilities.",
    },
  ];

const aviationInsuranceStakeholders = [
    { title: "Commercial airlines" },
    { title: "Private aircraft owners" },
    { title: "Charter operators" },
    { title: "Maintenance, repair, and overhaul (MRO) providers" },
    { title: "Flying schools and training institutions" },
    { title: "Drone operators" },
    { title: "Airport operators and ground handling companies" },
  ];

const addOnCoverages = [
    {
      title: "Cargo Insurance",
      description: "Covers loss or damage to goods transported by air.",
    },
    {
      title: "Flight Cancellation Insurance",
      description: "Protection against financial losses due to cancelled or delayed flights.",
    },
    {
      title: "Drone Insurance",
      description: "Specialized coverage for drones and unmanned aerial vehicles (UAVs).",
    },
    {
      title: "Cyber Risk Insurance",
      description: "Addresses risks related to data breaches and cyberattacks in the aviation sector.",
    },
  ];

const choosingAviationInsurance = [
    {
      title: "Assess Your Needs",
      description: "Identify the specific risks and coverage requirements based on your operations.",
    },
    {
      title: "Understand Coverage Types",
      description: "Familiarize yourself with the various types of aviation insurance and their benefits.",
    },
    {
      title: "Evaluate Policy Limits",
      description: "Ensure the policy limits are sufficient to cover potential losses.",
    },
    {
      title: "Check Exclusions",
      description: "Be aware of what is not covered under the policy to avoid surprises.",
    },
    {
      title: "Partner with Experts",
      description: "Work with ClearCover’s specialists to customize a policy tailored to your needs.",
    },
  ];
  
const reasonsToChooseClearCover = [
    {
      title: "Industry Expertise",
      description: "We understand the unique challenges of the aviation sector and offer solutions to address them effectively.",
    },
    {
      title: "Comprehensive Policies",
      description: "Our policies cover a wide range of risks, ensuring maximum protection for your operations.",
    },
    {
      title: "Flexible Plans",
      description: "Tailored solutions to meet the specific needs of aviation businesses, from small operators to large commercial airlines.",
    },
    {
      title: "Transparent Pricing",
      description: "Competitive premiums with no hidden costs.",
    },
    {
      title: "Quick Claims Processing",
      description: "Streamlined claims process to minimize disruptions to your operations.",
    },
  ];

const claimsProcessSteps = [
    {
      title: "Report the Incident",
      description: "Notify ClearCover immediately about the incident with relevant details.",
    },
    {
      title: "Submit Documentation",
      description: "Provide necessary documents, such as incident reports, photographs, and repair estimates.",
    },
    {
      title: "Claim Assessment",
      description: "Our team evaluates the claim to determine its validity and scope.",
    },
    {
      title: "Settlement",
      description: "We ensure prompt and hassle-free settlement to support your business continuity.",
    },
  ];

const aviationInsuranceExclusions = [
    { title: "Wear and tear or mechanical breakdowns" },
    { title: "Illegal operations or unlicensed pilots" },
    { title: "War and terrorism (unless specifically covered)" },
    { title: "Intentional acts or negligence" },
    { title: "Flights outside the agreed geographic area" },
  ];


const getStartedSteps = [
    {
      title: "Request a Quote",
      description: "Visit our website or contact our team for a personalized quote.",
    },
    {
      title: "Customize Your Policy",
      description: "Choose the coverage and add-ons that best match your needs.",
    },
    {
      title: "Activate Your Policy",
      description: "Complete the formalities and secure your aviation operations.",
    },
  ];
  
  
  
  
  

  
  

  
  
  
  

function AviationInsurance() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Aviation Insurance   </h1>
            </div>
            {/* <img 
                            src={assets.aviation} 
                            alt="Aviation Insurance" 
                            style={{ width: "100%", height: "", objectFit: "cover" }} 
            /> */}
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>Aviation Insurance Policy  </h1>
                <p>The aviation industry operates in a complex and high-risk environment, where safety and security are paramount. <span className='font-bold'>Aviation Insurance Coverage</span> is a specialized policy designed to provide financial protection for aircraft, passengers, crew, and third parties in case of accidents, damages, or liabilities. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we offer comprehensive Aviation Insurance solutions tailored to meet the unique needs of the aviation sector, ensuring peace of mind and financial stability.  </p>

                <h1 className='text-lg md:text-xl font-bold'>What is Aviation Insurance Policy?  </h1>
                <p>Aviation Insurance covers a broad range of risks associated with the operation of aircraft and the aviation industry. It includes protection for physical damage to aircraft, liability for passenger injuries, property damage, and legal obligations arising from aviation-related activities. </p>


                <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Key Features of Aviation Insurance </h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {aviationInsuranceFeatures.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why is Aviation Insurance Essential?</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyAviationInsuranceIsEssential.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Types of Aviation Insurance Coverage</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {aviationInsuranceCoverageTypes.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>



                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Aviation Insurance Coverage?</h2>
                    <p>Aviation Insurance is essential for various stakeholders in the aviation industry, including: </p>
                    <ol className="flex flex-col gap-4 list-disc list-inside ml-4">
                        {aviationInsuranceStakeholders.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] ">{coverage.title}: </span>
                                {/* {coverage.description} */}
                            </div>
                        </div>
                        ))}
                    </ol>


                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Coverages for Enhanced Protection</h2>
                    <p><a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> offers optional add-ons to extend the scope of your Aviation Insurance policy: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {addOnCoverages.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How to Choose the Right Aviation Insurance?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {choosingAviationInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for Aviation Insurance? </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {reasonsToChooseClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Does the Claims Process Work?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimsProcessSteps.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Common Exclusions in Aviation Insurance </h2>
                    <p>While Aviation Insurance provides comprehensive coverage, it typically excludes: </p>
                    <ol className="flex flex-col gap-4 list-disc list-inside ml-4">
                        {aviationInsuranceExclusions.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] ">{coverage.title}: </span>
                                {/* {coverage.description} */}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">The Importance of Aviation Insurance in Modern Aviation</h2>
                    <p>In today’s dynamic and interconnected world, aviation plays a critical role in global connectivity. Aviation Insurance not only protects financial assets but also ensures compliance, builds trust with stakeholders, and mitigates risks, enabling smoother operations in a high-stakes industry.</p>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Get Started with ClearCover Aviation Insurance </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {getStartedSteps.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <p>Aviation Insurance is more than just a financial safeguard - it’s a necessity for anyone involved in the aviation industry. With ClearCover, you can ensure your aircraft, crew, passengers, and business are protected against unforeseen risks, allowing you to focus on what matters most: delivering excellence in aviation.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>aviation insurance quote</span> or speak with our experts at <span className='font-bold'>+91 9133309629</span>. Let us help you soar with confidence! </p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs on Aviation Insurance Policy</h1>
                    <AviationFaq />
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default AviationInsurance