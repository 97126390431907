import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const CyberSecurityFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is cyber security insurance?",
      para:"",
      answer:
        "Cyber security insurance, also known as cyber liability insurance, is a policy that protects businesses and individuals against financial losses and liabilities resulting from cyberattacks, data breaches, ransomware, and other cyber-related incidents.",
    },
    {
      question: "What does cyber security insurance cover?",
      para:"Cyber insurance typically covers:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Costs of data breach response</li>
          <li>Business interruption losses due to cyber incidents</li>
          <li>Legal fees and regulatory fines</li>
          <li>Ransom payments for ransomware attacks</li>
          <li>Third-party claims for data breaches</li>
          <li>Forensic investigations and public relations expenses</li>
        </ul>
      ),
    },
    {
      question: "Who needs cyber security insurance?",
      para:"Any organization or individual handling sensitive data, such as personal information, financial records, or intellectual property, can benefit from cyber insurance. This includes:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Small and medium-sized enterprises (SMEs)</li>
          <li>E-commerce platforms</li>
          <li>Healthcare providers</li>
          <li>Financial institutions</li>
          <li>Freelancers handling client data</li>
        </ul>
      ),
    },
    {
      question: "How much does cyber security insurance cost?",
      para:"The cost of cyber insurance depends on several factors, including:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Size and type of business</li>
          <li>Industry and risk exposure</li>
          <li>Annual revenue</li>
          <li>Volume of sensitive data handled</li>
          <li>Existing cybersecurity measures</li>
        </ul>
      ),
    },
    {
      question: "What is not covered under cyber security insurance?",
      para:"Common exclusions include:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Pre-existing vulnerabilities or incidents</li>
          <li>Intentional acts of negligence</li>
          <li>Hardware damage or physical loss</li>
          <li>Loss of future profits</li>
          <li>Regulatory fines not explicitly covered</li>
        </ul>
      ),
    },
    {
      question: "How do I choose the right cyber insurance policy?",
      para:"To choose the right policy:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Assess your cyber risk exposure.</li>
          <li>Determine the type and extent of coverage you need (e.g., first-party, third-party).</li>
          <li>Look for add-ons like ransomware or social engineering fraud coverage.</li>
          <li>Consult with an expert to customize your policy.</li>
        </ul>
      ),
    },
    {
      question: "What should I do in case of a cyberattack?",
      para:"",
      answer: (
        <ol className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Notify your insurer immediately.</li>
          <li>Secure your systems and limit further damage.</li>
          <li>Document the incident and preserve evidence.</li>
          <li>Work with the insurer’s experts for forensic investigations and breach response.</li>
        </ol>
      ),
    },
    {
      question: "How does cyber insurance help with regulatory compliance?",
      para:"",
      answer:
        "Cyber insurance often includes coverage for legal fees and fines arising from non-compliance with data protection laws like GDPR or HIPAA. It also provides resources to ensure prompt breach notification and mitigation measures.",
    },
    {
      question: "Can individuals get cyber security insurance?",
      para:"",
      answer:
        "Yes, individuals such as freelancers, remote workers, or those handling sensitive personal data can benefit from cyber insurance to protect against risks like identity theft, data breaches, and cyber extortion.",
    },
    {
      question: "How can I reduce my cyber insurance premiums?",
      para:"You can lower premiums by:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Implementing robust cybersecurity measures (e.g., firewalls, encryption).</li>
          <li>Regularly training employees on cyber awareness.</li>
          <li>Conducting risk assessments and vulnerability scans.</li>
          <li>Having a comprehensive incident response plan in place.</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
          <div className="">
                <span>{faq.para}</span>
                <div
                  id={`faq-answer-${index}`}
                  className="faq-answer mt-2 text-[#555] transition-all duration-300"
                >
                  {faq.answer}
                </div>
          </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CyberSecurityFaq;
