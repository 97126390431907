import React from 'react'
import {Link} from 'react-router-dom'
import { assets } from '../assets/assets'
import { TbBrandTwitterFilled } from "react-icons/tb";
import { FaFacebook } from "react-icons/fa";
import { PiInstagramLogoBold } from "react-icons/pi";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";



function Footer() {
  return (
    
    <div className="py-14 bg-[#F4F7FA]">
         <div className=" max-w-[90%] sm:max-w-[80%] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-10  lg:gap-0">
            <ul className="flex flex-col gap-6">
                 <li className='text-[#61BC6D] text-[14px] lg:text-lg font-semibold'><Link to="">Company</Link></li>
                  <ul className='text-[12px] lg:text-[14px] flex flex-col gap-4 font-semibold'>
                    <li className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/about">About us</Link></li>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/contact">Contact us</Link></li>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/career">Careers</Link></li>
                  </ul>
            </ul>

            <ul className="flex flex-col gap-6 ">
                 <li className='text-[#61BC6D] text-[14px] lg:text-lg font-semibold'><Link to="">Product</Link></li>
                  <ul className='text-[12px] lg:text-[14px] flex flex-col gap-4 font-semibold'>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/insurance">Insurance</Link></li>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/firstBLog">Blogs</Link></li>
                    {/* <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="">News</Link></li> */}
                    {/* <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="">Help desk</Link></li> */}
                  </ul>
            </ul>

            <ul className="flex flex-col gap-6 ">
  <li className="text-[#61BC6D] text-[14px] lg:text-lg font-semibold">
    <Link to="">Logins</Link>
  </li>
  <ul className="text-[12px] lg:text-[14px] flex flex-col gap-4 font-semibold">
    <li className="transform transition-transform duration-300 ease-in-out hover:translate-x-1">
      <a href="https://corphelp.clearcover.in/login" target="_blank" rel="noreferrer">
        Employee
      </a>
    </li>
    <li className="transform transition-transform duration-300 ease-in-out hover:translate-x-1">
      <a href="https://corphelp.clearcover.in/hr_login/login" target="_blank" rel="noreferrer">
        HR Login
      </a>
    </li>
    <li className="transform transition-transform duration-300 ease-in-out hover:translate-x-1">
      <a href="https://corphelp.clearcover.in/crm-pages" target="_blank" rel="noreferrer">
        CRM Login
      </a>
    </li>
    {/* <li className="transform transition-transform duration-300 ease-in-out hover:translate-x-1">
      <Link to="">Get Started</Link>
    </li> */}
  </ul>
            </ul>

            <ul className="flex flex-col gap-6 ">
                 <li className='text-[#61BC6D] text-[14px] lg:text-lg font-semibold'><Link to="">Legal</Link></li>
                  <ul className='text-[12px] lg:text-[14px] flex flex-col gap-4 font-semibold'>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/privacy">Privacy Policy</Link></li>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/term">Terms & Conditions</Link></li>
                    <li  className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to="/sitemap">Sitemap</Link></li>
                  </ul>
            </ul>

            <ul className="flex flex-col gap-6 ">
                 <li className='text-[#61BC6D] text-[14px] lg:text-lg font-semibold'><Link to="">Contact us</Link></li>
                  <ul className='text-[12px] lg:text-[14px] flex flex-col gap-4 font-semibold'>
                    <li className='transform transition-transform duration-300 ease-in-out hover:translate-x-1'><Link to=""><img src={assets.footer_logo} alt="" className='w-[60%] ' /></Link></li>
                    <li className='flex flex-row items-center gap-1 ml-1 transform transition-transform duration-300 ease-in-out hover:translate-x-1'>
                        <MdOutlineMail  className='mt-1'/>
                        <a href="mailt gap-1o:support@clearcover.in">support@ClearCover.in</a>
                    </li>
                    <li className='flex flex-row gap-1 ml-1 transform transition-transform duration-300 ease-in-out hover:translate-x-1'>
                      <BsTelephone className='mt-1'/>
                      <a href="tel:+919553871111">+91 9133309629</a>
                    </li>
                  </ul>
            </ul>
            
         </div>
         <div className="max-w-[80%] mx-auto mt-20">
                
            <span className='flex flex-col justify-center items-center text-[14px] tracking-wide font-thin text-center'> 
                   <p>MDH Insurance is a registered Direct Broker | Registration No. 596, Registration Code No. DB 652/16,Valid till 30/04/2026,</p>
                   <p>License category- Direct Broker (General) | Visitors are informed that their information submitted on the website may be shared</p>
                   <p> with insurers. Product information is authentic and solely based on the information received from the insurers.</p>
            </span>
                 
               
             <hr className=' h-0.5  bg-[#292F41]' />
             <div className="grid lg:grid-flow-col-dense items-center justify-center lg:justify-between gap-4 px-6 mt-3">
                  <span className='text-[14px] tracking-wide font-thin text-center md:text-start'>© 2024 ClearCover.in. All Rights Reserved.</span>

                  <ul className="flex flex-row justify-center items-center gap-8">
                      <li> <a href="https://x.com/clearcover_in"><TbBrandTwitterFilled size={30} className='text-sky-500 '/></a></li>
                      <li> <a href="https://www.facebook.com/clearcoverin/"><FaFacebook size={30} className='text-blue-600 '/></a></li>
                      <li> <a href="https://www.instagram.com/clearcover_in/"><PiInstagramLogoBold size={30} className='text-red-500'/></a></li>
                      <li> <a href="https://www.linkedin.com/company/clearcover-insurance/"><FaLinkedin size={30} className='text-blue-700 '/></a></li>
                      <li> <a href="https://www.youtube.com/@clearcoverin"><FaYoutube size={30} className='text-red-500 '/></a></li>
                  </ul>
                    {/*text-[#E7E9ED] */ }
             </div>

         </div>
        
    </div>
  )
}

export default Footer