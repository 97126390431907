import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const ProfessionalIndemnityInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Professional Indemnity Insurance?",
      para:"",
      answer:
        "Professional Indemnity Insurance is a policy that protects professionals and businesses against claims of negligence, errors, omissions, or breach of duty arising from their professional services or advice.",
    },
    {
      question: "Who needs Professional Indemnity Insurance?",
      para:"This insurance is essential for professionals and businesses providing specialized advice or services, including:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Consultants (e.g., IT, business)</li>
          <li>Doctors and healthcare professionals</li>
          <li>Architects and engineers</li>
          <li>Accountants and financial advisors</li>
          <li>Legal professionals</li>
          <li>Freelancers and agencies</li>
        </ul>
      ),
    },
    {
      question: "What does Professional Indemnity Insurance cover?",
      para:"It typically covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Errors and omissions in professional services</li>
          <li>Negligence or breach of duty</li>
          <li>Legal defense costs</li>
          <li>Settlements and compensations</li>
          <li>Defamation (libel and slander)</li>
          <li>Loss of documents</li>
          <li>Intellectual property infringement</li>
        </ul>
      ),
    },
    {
      question: "What is not covered by Professional Indemnity Insurance?",
      para:"Exclusions may include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Criminal acts or deliberate misconduct</li>
          <li>General business risks (e.g., property damage)</li>
          <li>Claims made outside the policy period</li>
          <li>Regulatory fines and penalties unless explicitly covered</li>
          <li>Non-professional activities</li>
        </ul>
      ),
    },
    {
      question: "Is Professional Indemnity Insurance mandatory?",
      answer:
        "It depends on the industry and region. For some professions, such as healthcare or architecture, it may be a regulatory or contractual requirement.",
    },
    {
      question: "How is the premium for Professional Indemnity Insurance calculated?",
      para:"Premiums depend on factors like:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>The profession or industry</li>
          <li>Business size and revenue</li>
          <li>Coverage limits and deductibles</li>
          <li>Risk exposure</li>
          <li>Claims history</li>
        </ul>
      ),
    },
    {
      question: "Can freelancers and small businesses get Professional Indemnity Insurance?",
      answer:
        "Yes, freelancers and small businesses can purchase PI insurance to protect against professional liabilities. Customized plans are available to suit their specific needs and budgets.",
    },
    {
      question: "What happens if a claim is made against me?",
      para:"If a claim is made:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify your insurer immediately.</li>
          <li>Provide all relevant documents and evidence.</li>
          <li>The insurer will assess the claim and handle legal defense and compensation if applicable.</li>
        </ol>
      ),
    },
    {
      question: "Does the policy cover past incidents?",
      answer:
        "Some policies offer 'retroactive coverage,' which covers claims arising from incidents that occurred before the policy was purchased, as long as the claims are made during the policy period.",
    },
    {
      question: "Can I customize my Professional Indemnity Insurance policy?",
      answer:
        "Yes, most policies can be customized to include specific risks, coverage limits, and add-ons, such as intellectual property protection or cyber liability coverage, to meet your professional needs.",
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          
          {activeIndex === index && (
            <div className="">
               <span>{faq.para}</span>
                <div
                  id={`faq-answer-${index}`}
                  className="faq-answer mt-2 text-[#555] transition-all duration-300"
                >
                  {faq.answer}
                </div>
            </div>
           
          )}
        </div>
      ))}
    </div>
  );
};

export default ProfessionalIndemnityInsuranceFaq;
