import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import PlantMachineryInsuranceFaq from '../faq/PlantMachineryInsuranceFaq';
import { assets } from '../../assets/assets';


   const importance = [
      { 
        title: "Safeguards Financial Investments", 
        description: "Machinery and equipment are significant investments for any business. Insurance protects these assets from unexpected damages, ensuring your investment is secure." 
      },
      { 
        title: "Minimizes Operational Downtime", 
        description: "Equipment breakdowns can halt operations, leading to delays and financial losses. Insurance coverage ensures timely repairs or replacements, minimizing disruptions." 
      },
      { 
        title: "Compliance with Contracts and Regulations", 
        description: "Many industries and projects require machinery insurance as a standard safety measure, ensuring compliance with contractual and regulatory obligations." 
      },
      { 
        title: "Peace of Mind", 
        description: "With comprehensive coverage, businesses can focus on operations without worrying about potential losses due to equipment failure or damage." 
      },
    ];
    const coverage = [
      { title: "Accidental Damage", description: "Protection against damages caused by accidents, such as collisions, overturning, or external impacts." },
      { title: "Theft or Burglary", description: "Coverage for loss or damage due to theft or attempted burglary of insured machinery." },
      { title: "Natural Disasters", description: "Protection against damages caused by natural calamities, including earthquakes, floods, storms, and lightning." },
      { title: "Fire and Explosion", description: "Compensation for damages resulting from fire incidents or explosions." },
      { title: "Third-Party Liability", description: "Coverage for damages or injuries caused to third parties due to machinery operations." },
      { title: "Transit Coverage", description: "Protection for machinery transported between sites, covering damages during transit." },
      { title: "Machinery Breakdown", description: "Coverage for internal breakdowns, including mechanical or electrical failures." },
    ];
   const policyTypes =[
      { 
        title: "Contractor’s Plant and Machinery Insurance", 
        description: "Covers mobile machinery and equipment used in construction and related industries, such as cranes, bulldozers, and excavators." 
      },
      { 
        title: "Industrial All-Risk Policy", 
        description: "Comprehensive coverage for fixed and mobile plant installations, providing protection against multiple risks, including breakdowns and natural disasters." 
      },
      { 
        title: "Erection All Risk (EAR) Policy", 
        description: "Specifically designed for machinery being installed or erected at a site, covering risks during the installation phase." 
      },
      { 
        title: "Machinery Breakdown Policy", 
        description: "Focuses on mechanical and electrical breakdowns, ensuring quick repairs or replacements to minimize downtime." 
      },
    ];
    const benefits = [
      { title: "Comprehensive Coverage", description: "Our policies are designed to provide end-to-end protection, covering a wide range of risks and eventualities." },
      { title: "Tailored Solutions", description: "We understand that every business is unique. Our insurance solutions are customized to meet your specific needs." },
      { title: "Global Expertise", description: "ClearCover offers a wide network of service providers, ensuring seamless support for businesses operating across multiple locations." },
      { title: "Efficient Claim Process", description: "Our simplified claims process ensures quick settlements, helping you get back to business without delays." },
      { title: "Affordable Premiums", description: "We provide cost-effective policies, ensuring maximum coverage at competitive rates." },
    ];
   const targetAudience= [
      { title: "Construction Companies", description: "Businesses involved in construction projects rely heavily on expensive machinery, making insurance essential." },
      { title: "Manufacturing Units", description: "Factories and production facilities need coverage for machinery used in day-to-day operations." },
      { title: "Agricultural Enterprises", description: "Farmers and agricultural businesses require protection for tools and machinery, such as tractors and harvesters." },
      { title: "Mining and Quarrying Companies", description: "Industries operating heavy-duty equipment in harsh environments benefit significantly from machinery insurance." },
      { title: "Small and Medium Enterprises (SMEs)", description: "SMEs that depend on machinery for production or services need insurance to protect against unexpected breakdowns or damages." },
    ];
   const risksCovered= [
      { title: "Operational Accidents", description: "Damage due to operator error or accidental misuse." },
      { title: "Weather-Related Damages", description: "Protection from floods, storms, or other natural disasters." },
      { title: "Fire or Explosion", description: "Coverage for fire-related incidents causing equipment damage." },
      { title: "Theft and Vandalism", description: "Protection against theft or malicious damage to equipment." },
      { title: "Mechanical Failures", description: "Covers costs of repairing or replacing machinery due to breakdowns." },
    ];
   const howToChoose = [
      { title: "Assess Your Needs", description: "Identify the type of machinery you own, the risks involved, and your operational requirements." },
      { title: "Understand Coverage Options", description: "Compare policies to find one that offers the most comprehensive coverage for your business." },
      { title: "Check for Add-Ons", description: "Consider additional coverage options, such as transit insurance or third-party liability coverage." },
      { title: "Review Policy Exclusions", description: "Be aware of what is not covered, such as wear and tear, pre-existing damages, or negligence." },
      { title: "Consult with Experts", description: "Work with ClearCover’s team to design a policy tailored to your business needs." },
    ];
   const claimProcess = [
      { title: "Report the Incident", description: "Notify ClearCover immediately about any damage or loss." },
      { title: "Submit Required Documents", description: "Provide documents, such as the insurance policy, equipment details, and incident report." },
      { title: "Inspection and Assessment", description: "Our team will assess the damage and verify the claim." },
      { title: "Receive Compensation", description: "Once the claim is approved, we ensure quick and hassle-free settlement." },
    ];

  

function PlantMachineryInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Plant & Machinery Insurance </h1>
    </div>
    {/* <img 
                    src={assets.plant} 
                    alt="PlantMachinery Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Plant and Machinery Insurance Policy  </h1>
        <p>In industries reliant on heavy equipment and machinery, the risk of operational disruptions due to unforeseen damage or breakdowns is significant. Plant & Machinery Insurance is a specialized policy designed to safeguard these vital assets, ensuring your business remains protected against financial losses. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we provide tailored solutions to meet the diverse needs of industries, enabling seamless operations and peace of mind.  </p>


        <h1 className=' text-lg md:text-xl font-bold'>What is a Plant and Machinery Insurance Policy?</h1>
        <p>Plant & Machinery Insurance offers financial protection against accidental damages, breakdowns, or losses to machinery and equipment used in construction, manufacturing, agriculture, and other sectors. It ensures that businesses can recover quickly from equipment-related setbacks, minimizing downtime and financial impact.</p>

        <p className='mt-[-10px]'>This policy typically covers:</p>

        <ul className='list-inside list-disc ml-4 flex flex-col gap-3 mt-[-15px]'>
            <li>Construction equipment (e.g., cranes, excavators)</li>
            <li>Manufacturing machinery</li>
            <li>Agricultural tools and equipment</li>
            <li>Plant installations and fixed assets</li>
        </ul>


        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Importance of Plant and Machinery Insurance Coverage</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {importance.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Coverage Offered by Plant and Machinery Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {coverage.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Types of Plant and Machinery Insurance Policies</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {policyTypes.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of ClearCover Plant and Machinery Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {benefits.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Plant and Machinery Insurance?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {targetAudience.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Common Risks Covered</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {risksCovered.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How to Choose the Right Plant and Machinery Insurance Policy</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howToChoose.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Claim Process</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Partner with ClearCover for Your Insurance Needs</h2>
                    <p>At ClearCover, we are committed to providing reliable, transparent, and affordable insurance solutions for your plant and machinery. Whether you are in construction, manufacturing, or agriculture, we ensure your business remains protected against unexpected challenges.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'> plant & machinery insurance quote </span>  quote or speak with our experts at <span className='font-bold'>+91 9133309629</span>. </p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs About Plant and Machinery Insurance Policy</h1>
                    <PlantMachineryInsuranceFaq/>
                </div>

    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default PlantMachineryInsurance