import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const MarineInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is marine insurance, and why is it important?",
      para:"",
      answer:
        "Marine insurance is a type of insurance that provides coverage for goods, vessels, and associated liabilities during transit over waterways, air, or land. It protects against risks like damage, theft, and accidents, ensuring financial security for businesses and individuals involved in shipping.",
    },
    {
      question: "What types of marine insurance policies are available?",
      para:"Marine insurance includes various policies, such as:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Voyage Policy (single trip coverage)</li>
          <li>Time Policy (fixed duration coverage)</li>
          <li>Open Policy (continuous coverage for multiple shipments)</li>
          <li>Hull Insurance (vessel protection)</li>
          <li>Freight Insurance (protection for freight revenue)</li>
        </ul>
      ),
    },
    {
      question: "What does marine insurance typically cover?",
      para:"Marine insurance generally covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Loss or damage to cargo</li>
          <li>Accidents (e.g., sinking, collisions)</li>
          <li>Natural disasters (e.g., storms, cyclones)</li>
          <li>Fire and explosion</li>
          <li>Theft and piracy</li>
          <li>Third-party liabilities</li>
        </ul>
      ),
    },
    {
      question: "What is excluded from marine insurance coverage?",
      para:"Common exclusions include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Damage due to wear and tear</li>
          <li>Wilful misconduct or negligence</li>
          <li>Improper packaging of goods</li>
          <li>Losses from delays unless specified in the policy</li>
          <li>War or nuclear risks (unless covered by special endorsements)</li>
        </ul>
      ),
    },
    {
      question: "Is marine insurance mandatory for international trade?",
      para:"",
      answer:
        "While not always legally required, marine insurance is highly recommended for international trade. Many shipping contracts require insurance to protect the interests of both the shipper and the consignee.",
    },
    {
      question: "How is the premium for marine insurance calculated?",
      para:"Premiums are determined by several factors, including:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Type of goods being shipped</li>
          <li>Value of the cargo</li>
          <li>Mode of transportation (sea, air, or land)</li>
          <li>Shipping route and associated risks</li>
          <li>Policy type and coverage extensions</li>
        </ul>
      ),
    },
    {
      question: "Can marine insurance cover delays in transit?",
      para:"",
      answer:
        "Standard marine insurance policies do not typically cover delays unless specific coverage extensions are purchased. These can compensate for financial losses due to delays in certain circumstances.",
    },
    {
      question: "How do I file a claim under marine insurance?",
      para:"The steps are:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify the insurer immediately about the incident.</li>
          <li>Provide necessary documentation, such as the policy, shipping invoice, and damage report.</li>
          <li>Cooperate with the insurer during the inspection and assessment.</li>
          <li>Receive the settlement based on the policy terms.</li>
        </ol>
      ),
    },
    {
      question: "Who needs marine insurance?",
      para:"Marine insurance is essential for:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Exporters and importers</li>
          <li>Freight forwarders and logistics providers</li>
          <li>Vessel and shipowners</li>
          <li>Businesses involved in domestic or international cargo transit</li>
        </ul>
      ),
    },
    {
      question: "Can I customize a marine insurance policy?",
      answer:
        "Yes, most insurers offer customizable marine insurance policies. You can tailor the coverage to include specific risks, routes, and cargo types to best suit your needs.",
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MarineInsuranceFaq;
