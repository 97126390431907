import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import ConstructionFaq from '../faq/ConstructionFaq';
import { assets } from '../../assets/assets';

const constructionInsuranceFeatures = [
    { title: "Comprehensive Coverage", description: "Protects against a wide range of risks, including property damage, theft, natural disasters, and accidents." },
    { title: "Tailored Policies", description: "Customized solutions to address the unique needs of different construction projects." },
    { title: "Multi-Stakeholder Protection", description: "Coverage extends to project owners, contractors, subcontractors, and workers." },
    { title: "Liability Coverage", description: "Safeguards against third-party claims for bodily injury or property damage." },
    { title: "Business Continuity Support", description: "Ensures financial stability in the face of unexpected delays or damages." },
  ];

const constructionInsuranceCoverage = [
    {
      title: "Contractor’s All Risk (CAR) Insurance",
      content: [
        "Covers physical damage to the construction site, equipment, and materials due to unforeseen events like fire, storms, theft, or vandalism.",
        "Includes coverage for third-party liabilities arising from the project."
      ]
    },
    {
      title: "Worker's Compensation Insurance",
      content: [
        "Provides protection for workers in case of accidents, injuries, or fatalities occurring on the construction site.",
        "Ensures compliance with legal and regulatory requirements for worker safety."
      ]
    },
    {
      title: "Equipment Insurance",
      content: [
        "Covers construction machinery and equipment against damage or theft.",
        "Includes coverage for rented or owned equipment used on-site."
      ]
    },
    {
      title: "Third-Party Liability Insurance",
      content: [
        "Protects against legal liabilities arising from injuries or damages caused to third parties during construction activities.",
        "Essential for projects located in densely populated or high-traffic areas."
      ]
    },
    {
      title: "Delay in Start-Up (DSU) Insurance",
      content: [
        "Covers financial losses due to project delays caused by insured risks like property damage or natural disasters."
      ]
    },
    {
      title: "Builder’s Risk Insurance",
      content: [
        "Provides coverage for buildings under construction, including materials, supplies, and equipment on-site.",
        "Covers risks such as fire, vandalism, windstorms, and other perils."
      ]
    },
    {
      title: "Professional Liability Insurance",
      content: [
        "Protects architects, engineers, and contractors from claims arising due to design errors, negligence, or omissions."
      ]
    },
    {
      title: "Environmental Liability Insurance",
      content: [
        "Covers liabilities associated with pollution or environmental damage caused during construction."
      ]
    }
  ];
  

const constructionInsuranceBenefits = [
    { 
      title: "Risk Mitigation", 
      description: "Construction insurance provides financial protection against unpredictable risks, ensuring that your project remains on track despite challenges."
    },
    { 
      title: "Legal Compliance", 
      description: "Most construction projects are legally required to have specific insurance coverage, such as worker’s compensation and liability insurance."
    },
    { 
      title: "Peace of Mind", 
      description: "A comprehensive construction insurance policy ensures all stakeholders can focus on project execution without worrying about potential financial setbacks."
    },
    { 
      title: "Financial Stability", 
      description: "In the event of a covered loss or delay, insurance helps you recover quickly, minimizing disruptions to your operations and cash flow."
    },
    { 
      title: "Enhanced Reputation", 
      description: "Demonstrating a commitment to safety and risk management through adequate insurance coverage enhances your reputation with clients and stakeholders."
    },
  ];

const whyChooseClearCoverConstruction = [
    { 
      title: "Customizable Coverage", 
      description: "Tailored solutions to meet the requirements of different construction types and scales."
    },
    { 
      title: "Competitive Premiums", 
      description: "Affordable pricing without compromising on comprehensive protection."
    },
    { 
      title: "Expert Guidance", 
      description: "Dedicated support from industry experts to help you choose the right coverage."
    },
    { 
      title: "Hassle-Free Claims", 
      description: "Transparent and efficient claims process to minimize downtime."
    },
    { 
      title: "24/7 Support", 
      description: "Round-the-clock assistance to address your queries and concerns."
    },
  ];

const stepsToSecureConstructionInsurance = [
    { 
        title: "Evaluate Your Needs", 
      description: "Assess the specific risks and requirements of your construction project."
    },
    { 
        title: "Get a Quote", 
      description: "Reach out to ClearCover for a personalized quote."
    },
    { 
        title: "Choose Your Policy", 
      description: "Select the coverage options that align with your project’s needs."
    },
    { 
        title: "Submit Documentation", 
      description: "Provide the necessary details and documents for policy issuance."
    },
    { 
        title: "Activate Your Policy", 
      description: "Complete the process to ensure your project is protected."
    },
  ];

const constructionProjectsCovered = [
    { title: "Residential Buildings", description: "Insurance coverage for housing projects and apartments." },
    { title: "Commercial Complexes", description: "Comprehensive protection for malls, office buildings, and other commercial properties." },
    { title: "Infrastructure Projects", description: "Coverage for large-scale projects such as bridges, roads, and railways." },
    { title: "Industrial Plants", description: "Insurance for factories, manufacturing plants, and industrial facilities." },
    { title: "Renovation and Remodeling Projects", description: "Protection for upgrades and modifications to existing structures." },
  ];

const constructionInsuranceAddOns = [
    { 
      title: "Terrorism Coverage", 
      description: "Protection against damages caused by acts of terrorism." 
    },
    { 
      title: "Natural Disaster Coverage", 
      description: "Additional protection for earthquakes, floods, and other natural calamities." 
    },
    { 
      title: "Temporary Structure Coverage", 
      description: "Covers temporary setups like scaffolding, tents, or sheds on construction sites." 
    },
    { 
      title: "Debris Removal", 
      description: "Financial assistance for the removal of debris after a covered event." 
    },
  ];
  
  
  
  
  
  

  
  

  
  
  

function ConstructionInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Construction Insurance   </h1>
    </div>

    {/* <img 
                    src={assets.constructionn} 
                    alt="Construction Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
      /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Construction Insurance Policy </h1>
        <p>Construction projects, while essential for growth and development, are inherently risky. From unexpected delays to accidents and damages, a range of uncertainties can impact the timeline and financial stability of a project. This is where <span className='font-bold'>construction insurance </span>plays a pivotal role. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we offer tailored <span className='font-bold'>construction insurance policy </span>designed to protect your projects, assets, and personnel, ensuring peace of mind at every stage of construction. </p>

        <h1 className=' text-lg md:text-xl font-bold'>What is Construction Insurance Policy? </h1>
        <p>Construction insurance is a specialized form of insurance that provides financial protection against risks and liabilities associated with construction projects. It is designed to cover damages, delays, or liabilities that may arise during the planning, execution, or completion of a project. </p>

        <h1 className=' text-lg md:text-xl font-bold'>Importance of Construction Insurance Coverage </h1>
        <p>Construction projects involve multiple stakeholders, including developers, contractors, subcontractors, and workers. Without adequate insurance coverage, unforeseen events can lead to significant financial losses, legal liabilities, and reputational damage. A robust <span className='font-bold'>construction insurance policy </span> ensures that all parties involved are protected, minimizing disruptions and safeguarding the project's success. </p>

        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Key Features of Construction Insurance</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {constructionInsuranceFeatures.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Construction Insurance Coverage</h2>
                    <p> <span className='font-bold'>A construction insurance policy</span> typically includes the following coverage options:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                    {constructionInsuranceCoverage.map((feature, index) => (
                        <li key={index} className="flex flex-col gap-2 text-[17px]">
                        <span className="text-[16px] font-bold">{feature.title}: </span>
                        <ul className="list-disc list-inside ml-4">
                            {feature.content.map((item, i) => (
                             <div className='flex flex-row gap-1'>
                                <li className="text-[17px]"> </li>
                                <p key={i} className="text-[15px]">{item}</p>
                             </div>
                            
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ol>



                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of Construction Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {constructionInsuranceBenefits.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>



                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for Construction Insurance?</h2>
                    <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> we understand that every construction project is unique. Our construction insurance policies are designed to address the specific needs of your project, offering:</p>
                    <ol className="flex flex-col gap-4 list-disc list-inside ml-4">
                        {whyChooseClearCoverConstruction.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] font-bold ">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>


                    <h2 className="text-lg md:text-xl font-bold mt-6">Steps to Secure Construction Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {stepsToSecureConstructionInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Types of Construction Projects Covered</h2>
                    <p>Our <span className='font-bold'>construction insurance coverage </span> extends to a wide range of projects, including:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {constructionProjectsCovered.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-Ons for Enhanced Coverage </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {constructionInsuranceAddOns.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>


                    <p>Construction projects are high-stakes ventures that demand meticulous planning and risk management. A well-structured <span className='font-bold'>construction insurance policy </span> from ClearCover ensures comprehensive protection for your project, assets, and personnel. With customizable options and expert support, we empower you to navigate challenges confidently and achieve your goals. </p>

                    <p>Ready to secure your construction project? <a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a>  today for a <span className='font-bold'>construction insurance quote </span>  or speak with our experts at <span className='font-bold'> +91 9133309629</span>.</p>
        </div>

        <div className="text-[14px]">
                <h1 className=' text-lg md:text-xl font-bold mb-4'>FAQs on Construction Insurance Policy</h1>
                <ConstructionFaq/>
        </div>





    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default ConstructionInsurance