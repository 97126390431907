// import React, { useState } from 'react';
// import { MdOutlineArrowDropDown } from 'react-icons/md';
// import { RiArrowDropRightFill } from 'react-icons/ri';

// const Sitemap = () => {
//   // Static list of sitemap URLs
//   const urls = [
//     'https://clearcover.in/',
//     'https://clearcover.in/about',
//     'https://clearcover.in/contact',
//     'https://clearcover.in/career',
//     'https://clearcover.in/login',
//     'https://clearcover.in/insurance',
//     'https://clearcover.in/placementassociate',
//     'https://clearcover.in/jobform',
//     'https://clearcover.in/term',
//     'https://clearcover.in/team',
//     'https://clearcover.in/privacy',
//     'https://clearcover.in/sitemap',
//     'https://clearcover.in/insurancemain',
//     'https://clearcover.in/grouphealth',
//     'https://clearcover.in/fire',
//     'https://clearcover.in/office',
//     'https://clearcover.in/groupaccidental',
//     'https://clearcover.in/generalliability',
//     'https://clearcover.in/aviation',
//     'https://clearcover.in/construction',
//     'https://clearcover.in/marine',
//     'https://clearcover.in/cybersecurity',
//     'https://clearcover.in/directors',
//     'https://clearcover.in/plant',
//     'https://clearcover.in/professional',
//     'https://clearcover.in/workman',
//     'https://clearcover.in/firstBLog',
//     'https://clearcover.in/bloggrouphealthinsurance',
//     'https://clearcover.in/claimintimation',
//     'https://clearcover.in/uploaddoc',
//     'https://clearcover.in/downloadClaim',
//     'https://clearcover.in/escalationMatrix',
//     'https://clearcover.in/unlock',    
//   ];

//   // State to track whether URLs and <urlset> are truncated
//   const [truncatedUrls, setTruncatedUrls] = useState(urls.map(() => false)); // Track each URL visibility
//   const [isUrlsetExpanded, setIsUrlsetExpanded] = useState(false); // Track <urlset> visibility

//   // Toggle visibility of a specific URL
//   const toggleUrl = (index) => {
//     setTruncatedUrls((prev) =>
//       prev.map((truncated, i) => (i === index ? !truncated : truncated))
//     );
//   };

//   // Toggle visibility of <urlset>
//   const toggleUrlset = () => {
//     setIsUrlsetExpanded((prev) => !prev);
//   };

//   return (
//     <div className="container mx-auto p-4 mt-3">
//       <p className="text-lg font-semibold text-gray-600 mb-4">
//         This XML file does not appear to have any style information associated with it. The document tree is shown below.
//         <hr className='w-[64%] h-[2px] bg-black' />
//       </p>

//       {/* <urlset> Toggle Section */}
//       <div className="flex items-center cursor-pointer" onClick={toggleUrlset}>
//         {isUrlsetExpanded ? (
//           <MdOutlineArrowDropDown className="text-lg mr-1" />
//         ) : (
//           <RiArrowDropRightFill className="text-lg mr-1" />
//         )}
//         <span className="text-sm text-[#1A1AA6] mb-4">
//           <span className='text-[#881280]'>&lt;urlset</span> 
//           <span className='text-[#994500]'> xmlns</span> 
//           <span className='text-[#881280]'>="</span> http://www.sitemaps.org/schemas/sitemap/0.<span className='text-[#881280]'>" </span> 
//           <span className='text-[#994500]'>xmlns:xsi</span> 
//           <span className='text-[#881280]'>="</span>http://www.w3.org/2001/XMLSchema-instance<span className='text-[#881280]'>" </span> 
//           <span className='text-[#994500]'>xsi:schemaLocation</span> 
//           <span className='text-[#881280]'>="</span>http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd<span className='text-[#881280]'>"&gt;</span> 
//         </span>
//       </div>

//        {/* Footer Note */}
//        <p className="text-sm text-green-600 mb-4">
//         &lt;!-- created with Free Online Sitemap Generator www.xml-sitemaps.com --&gt;
//       </p>

//       {/* URL List */}
//       {isUrlsetExpanded && (
//         <div className="text-[13px] text-[#999999] pl-5">
//           {urls.map((url, index) => (
//             <div className="flex flex-col" key={index}>
//               {/* Toggle Button */}
//               <div className="flex items-center cursor-pointer" onClick={() => toggleUrl(index)}>
//                 {truncatedUrls[index] ? (
//                   <RiArrowDropRightFill className="text-lg mr-1" />
//                 ) : (
//                   <MdOutlineArrowDropDown className="text-lg mr-1" />
//                 )}
//                 <span className='text-[#881280]'>&lt;url&gt;</span>
//               </div>

//               {/* URL Content */}
//               <div className="">
//                 <span className="ml-8 text-[#881280]">
//                   &lt;loc&gt;
//                   {truncatedUrls[index] ? (
//                     <span className="text-[#000000]">...</span>
//                   ) : (
//                     <a
//                       href={url}
//                       className="hover:underline text-[#000000]"
//                       target="_blank"
//                       rel="noopener noreferrer"
//                     >
//                       {url}
//                     </a>
//                   )}
//                   &lt;/loc&gt;
//                 </span>
//                 <p className="ml-4 text-[#881280]">&lt;/url&gt;</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}

//       {/* Closing <urlset> */}
//       <p className="text-sm text-gray-600 mt-4">
//         &lt;/urlset&gt;
//       </p>

     
//     </div>
//   );
// };





// export default Sitemap;


import React, { useState } from 'react';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { RiArrowDropRightFill } from 'react-icons/ri';
import {Link} from 'react-router-dom';

const Sitemap = () => {
  // Define categories and their respective routes
  const sitemap = [
      { path: "/", name: "Home" },
      { path: "/about", name: "About" },
      { path: "/insurance", name: "Products" },
      { path: "/contact", name: "Contact" },
      { path: "/login", name: "Login" },
      { path: "/career", name: "Career" },
      { path: "/fullstack", name: "Full Stack Developer" },
      { path: "/placementassociate", name: "Placement Associate" },
      { path: "/jobform", name: "Job Form" },
      { path: "/term", name: "Term" },
      { path: "/team", name: "Team" },
      { path: "/privacy", name: "Policy Privacy" },
      { path: "/sitemap", name: "Sitemap" },
      
      { path: "/grouphealth", name: "Group Health Insurance" },
      { path: "/fire", name: "Fire Insurance" },
      { path: "/office", name: "Office Package Policy" },
      { path: "/groupaccidental", name: "Group Accidental Insurance" },
      { path: "/generalliability", name: "General Liability Insurance" },
      { path: "/aviation", name: "Aviation Insurance" },
      { path: "/construction", name: "Construction Insurance" },
      { path: "/marine", name: "Marine Insurance" },
      { path: "/cybersecurity", name: "Cyber Security Insurance" },
      { path: "/plant", name: "Plant Machinery Insurance" },
      { path: "/professional", name: "Professional Indemnity Insurance" },
      { path: "/workman", name: "Workmen Compensation Insurance" },
      { path: "/firstBLog", name: "First Blog" },
      { path: "/bloggrouphealthinsurance", name: "Group Health Insurance Blog" },
      { path: "/claimintimation", name: "Claim Intimation" },
      { path: "/uploaddoc", name: "Upload Documents" },
      { path: "/downloadClaim", name: "Download Claim Form" },
      { path: "/escalationMatrix", name: "Escalation Matrix" },
      { path: "/unlock", name: "Unlock Form Campaign" },
];

  // State to track which categories are expanded
  const [expandedCategories, setExpandedCategories] = useState(
    Object.keys(sitemap).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  // Toggle category visibility
  const toggleCategory = (category) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  return (
    <div className=" max-w-[90%
    ] lg:max-w-[80%] mx-auto px-4 py-14">
      <h1 className="text-2xl font-bold mb-4">ClearCover Sitemap</h1>
      <div className=" grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-10 text-[14px]">
        {Object.entries(sitemap).map(([category, links]) => (
          <Link
          to={links.path}
          className="hover:underline hover:text-blue-500 font-serif text-gray-700"
          >
          {links.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Sitemap;

