import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import CyberSecurityFaq from '../faq/CyberSecurityFaq';
import { assets } from '../../assets/assets';

const cyberSecurityCoverage = [
    {
      title: "Rising Cyber Threats",
      content: [
        "The frequency and sophistication of cyberattacks are increasing globally. A robust cyber security insurance policy ensures financial protection against these ever-evolving threats."
      ]
    },
    {
      title: "Compliance Requirements",
      content: [
        "MMany industries are governed by strict data protection regulations. Cyber insurance helps meet these compliance requirements by covering penalties and fines in certain cases."
      ]
    },
    {
      title: "Reputational Protection",
      content: [
        "A cyberattack can harm your brand’s reputation. Cyber insurance provides resources to manage public relations and restore customer trust."
      ]
    },
    {
      title: "Business Continuity",
      content: [
        "Cyberattacks can lead to operational disruptions. Insurance coverage ensures minimal downtime and helps you get back to business quickly."
      ]
    }
  ];

  const cyberSecurityCoverageOffered = [
    {
      title: "First-Party Coverage",
      headings: ["Data Breach Response", "Cyber Extortion", "Business Interruption", "Forensic Investigation"],
      content: [
        "Covers costs for notifying affected parties, credit monitoring, and public relations efforts.",
        "Protection against ransomware attacks, including negotiation and payment assistance.",
        "Compensation for income loss due to downtime caused by a cyber incident.",
        "Covers expenses related to identifying the source and extent of the breach."
      ]
    },
    {
      title: "Third-Party Coverage",
      headings: ["Legal Liability", "Regulatory Fines", "Media Liability"],
      content: [
        "Covers claims from customers or third parties due to data breaches or privacy violations.",
        "Covers penalties imposed by regulatory bodies for non-compliance with data protection laws.",
        "Protection against defamation, copyright infringement, or intellectual property breaches caused by online activities."
      ]
    },
    {
      title: "Optional Add-ons",
      headings: ["Social Engineering Fraud", "Cryptocurrency Coverage"],
      content: [
        "Coverage for financial losses due to phishing or impersonation schemes.",
        "Protection against theft or loss of digital assets like cryptocurrencies."
      ]
    }
  ];

  const whoNeedsCyberSecurityInsurance = [
    {
      title: "Small and Medium-Sized Enterprises (SMEs)",
      content: "SMEs are often targeted by cybercriminals due to limited cybersecurity resources. Cyber insurance provides an affordable way to mitigate risks."
    },
    {
      title: "Large Corporations",
      content: "With vast amounts of sensitive data, large corporations require comprehensive policies to protect against high-profile attacks."
    },
    {
      title: "E-Commerce Businesses",
      content: "Online retailers are highly vulnerable to payment fraud, data breaches, and DDoS attacks."
    },
    {
      title: "Healthcare Providers",
      content: "Healthcare organizations handle sensitive patient data, making them prime targets for ransomware and breaches."
    },
    {
      title: "Freelancers and Individuals",
      content: "Cyber insurance isn’t just for businesses. Freelancers and individuals who handle sensitive client data can also benefit from protection."
    }
  ];

const benefitsOfClearCoverCyberInsurance = [
    {
      title: "Comprehensive Protection",
      content: "Our policies cover a wide range of cyber threats, ensuring end-to-end security for your digital assets."
    },
    {
      title: "Customizable Plans",
      content: "We offer tailored solutions to meet the unique requirements of your business or individual needs."
    },
    {
      title: "Expert Support",
      content: "Access to cybersecurity experts for breach response, forensic investigations, and risk assessments."
    },
    {
      title: "Rapid Claims Processing",
      content: "Hassle-free claims process with a dedicated team to ensure quick resolutions."
    },
    {
      title: "Cost-Effective Solutions",
      content: "Affordable premiums without compromising on the quality of coverage."
    }
  ];

const howCyberSecurityInsuranceWorks = [
    {
      title: "Risk Assessment",
      content: "Our team conducts a thorough evaluation of your business’s cybersecurity measures, identifying vulnerabilities and potential risks."
    },
    {
      title: "Policy Customization",
      content: "Based on the assessment, we design a policy tailored to your specific needs, ensuring comprehensive coverage."
    },
    {
      title: "Incident Response",
      content: "In the event of a cyberattack, our team provides immediate support, from breach containment to legal consultation."
    },
    {
      title: "Claims Settlement",
      content: "We streamline the claims process to minimize delays, ensuring you receive compensation quickly."
    }
  ];

const stepsToGetCyberSecurityInsurance = [
    {
      step: "Evaluate Your Needs",
      description: "Assess the size of your business, the type of data you handle, and potential risks."
    },
    {
      step: "Choose the Right Policy",
      description: "Select from our range of plans, including first-party, third-party, or comprehensive coverage."
    },
    {
      step: "Request a Quote",
      description: "Get a customized quote based on your specific requirements."
    },
    {
      step: "Purchase the Policy",
      description: "Complete the process online or through one of our expert advisors."
    },
    {
      step: "Secure Your Digital Assets",
      description: "Enjoy peace of mind knowing that your business is protected from cyber threats."
    }
  ];

const realWorldCyberInsuranceScenarios = [
    {
      title: "Ransomware Attack",
      description: "A mid-sized company experiences a ransomware attack, locking access to critical data. Cyber insurance covers ransom payments and data recovery costs."
    },
    {
        title: "Data Breach",
      description: "A healthcare provider faces a breach exposing patient records. Insurance covers breach notification, legal fees, and reputation management."
    },
    {
        title: "Phishing Scam",
      description: "An employee falls for a phishing email, resulting in financial fraud. The policy compensates for the loss and implements training to prevent future incidents."
    }
  ];
  
function CyberSecurityInsurance() {
  return (
  <div className="py-25 ">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Cyber Security Insurance </h1>
    </div>

    {/* <img 
                    src={assets.cyber} 
                    alt="Cyber Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Cyber Security Insurance Policy</h1>
        <p>In today’s hyperconnected world, the importance of cybersecurity cannot be overstated. Businesses and individuals face an ever-growing threat of cyberattacks, data breaches, and financial fraud, all of which can result in severe financial and reputational losses. Cyber security insurance is a critical safeguard that helps protect against these risks, offering coverage for data breaches, system downtime, legal expenses, and more. </p>

        <p>
        At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we provide comprehensive cyber security insurance solutions tailored to meet the unique needs of businesses and individuals. Our goal is to help you navigate the complexities of the digital landscape with confidence and peace of mind.
        </p>

        <h1 className=' text-lg md:text-xl font-bold'>What is Cyber Security Insurance Policy? </h1>
        <p>Cyber security insurance, also known as cyber liability insurance, is a policy designed to help businesses and individuals mitigate risks associated with cyber threats. It covers the financial losses and legal liabilities that may arise from cyber incidents such as hacking, ransomware attacks, phishing scams, and data breaches.</p>

        <p>This type of insurance not only safeguards digital assets but also assists with crisis management, including breach notifications, forensic investigations, and reputation recovery.</p>

        <div className="flex flex-col gap-4">
                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Cyber Security Insurance Coverage is Essential</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                    {cyberSecurityCoverage.map((feature, index) => (
                        <li key={index} className="flex flex-col gap-2 text-[17px]">
                        <span className="text-[16px] font-bold">{feature.title}: </span>
                        <ul className="list-disc list-inside ml-4">
                            {feature.content.map((item, i) => (
                             <div className='flex flex-row gap-1'>
                                <li className="text-[17px]"> </li>
                                <p key={i} className="text-[15px]">{item}</p>
                             </div>
                            
                            ))}
                        </ul>
                        </li>
                    ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Coverage Offered by Cyber Security Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                      {cyberSecurityCoverageOffered.map((feature, index) => (
                        <li key={index} className="flex flex-col gap-2 text-[17px]">
                          <span className="text-[16px] font-bold">{feature.title}:</span>
                          <ul className="list-disc list-inside ml-4">
                            {feature.headings.map((heading, i) => (
                              <div key={i} className="flex flex-row gap-2 ">
                                <li className="text-[17px]"></li>
                                <div className="">
                                  <span className="text-[16px] font-bold inline">{heading}: </span>
                                  {feature.content[i]}
                                </div>
                              </div>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ol>;

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Cyber Security Insurance Policy? </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whoNeedsCyberSecurityInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.content}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of ClearCover Cyber Security Insurance Coverage </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {benefitsOfClearCoverCyberInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.content}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Cyber Security Insurance Coverage Works</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howCyberSecurityInsuranceWorks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.content}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Steps to Get Cyber Security Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {stepsToGetCyberSecurityInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.step}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Real-World Scenarios Where Cyber Insurance Helps</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {realWorldCyberInsuranceScenarios.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Business with ClearCover</h2>

                    <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we understand the challenges of navigating today’s digital threats. Our cyber security insurance solutions are designed to provide comprehensive protection, expert support, and peace of mind, ensuring your business thrives in a secure environment.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a>  today for a <span className='font-bold'>cyber security insurance quote </span>  or speak with our experts at <span className='font-bold'> +91 9133309629</span>.</p>
        </div>

        <div className="text-[14px]">
                <h1 className=' text-lg md:text-xl font-bold mb-4'>FAQs About Cyber Security Insurance</h1>
                <CyberSecurityFaq/>
        </div>
              
    </div>
    <div className="">
        <InsuranceSlider/>
    </div>
</div>
  )
}

export default CyberSecurityInsurance