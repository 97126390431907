import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const OfficePackageInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Office Package Insurance?",
      para:"",
      answer:
        "Office Package Insurance is a comprehensive policy designed to protect businesses from various risks, including property damage, burglary, liability claims, and operational interruptions. It provides consolidated coverage to safeguard office premises, assets, and operations.",
    },
    {
      question: "Who should buy Office Package Insurance?",
      para:"This policy is ideal for:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Small and Medium Enterprises (SMEs)</li>
          <li>Large corporate offices</li>
          <li>Retail businesses</li>
          <li>Co-working spaces</li>
          <li>Professionals operating from home offices</li>
        </ul>
      ),
    },
    {
      question: "What does Office Package Insurance typically cover?",
      para:"The policy provides coverage for:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Fire and property damage</li>
          <li>Theft and burglary</li>
          <li>Business interruption losses</li>
          <li>Third-party liability</li>
          <li>Damage to electronic equipment</li>
          <li>Glass breakage</li>
          <li>Employee-related liabilities</li>
        </ul>
      ),
    },
    {
      question: "What add-ons are available with Office Package Insurance?",
      para:"Add-ons to enhance coverage include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Natural disaster protection (e.g., floods, earthquakes)</li>
          <li>Terrorism cover</li>
          <li>Loss of valuable documents</li>
          <li>Portable equipment coverage for items used off-premises</li>
          <li>Tenant liability insurance</li>
        </ul>
      ),
    },
    {
      question: "Is third-party liability included in Office Package Insurance?",
      answer:
        "Yes, third-party liability is a standard feature in most Office Package Insurance policies. It covers legal liabilities arising from accidental injuries or damages caused to third parties on your premises.",
    },
    {
      question: "Does Office Package Insurance cover employee-related liabilities?",
      answer:
        "Yes, the policy often includes coverage for workplace accidents or injuries to employees. This may vary based on the plan, so it’s important to review the specifics with your insurer.",
    },
    {
      question: "How is the premium for Office Package Insurance calculated?",
      para:"The premium depends on several factors, including:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>The size and location of your office</li>
          <li>The value of assets and inventory</li>
          <li>Coverage options and add-ons selected</li>
          <li>Industry-specific risks</li>
        </ul>
      ),
    },
    {
      question: "What is not covered under Office Package Insurance?",
      para:"Exclusions may include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Intentional damage or illegal activities</li>
          <li>Wear and tear or regular maintenance costs</li>
          <li>Damage caused by war or nuclear risks (unless specifically covered)</li>
          <li>Losses outside the specified geographic coverage area</li>
        </ul>
      ),
    },
    {
      question: "How do I file a claim under Office Package Insurance?",
      para:"To file a claim:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify the insurer immediately after the incident.</li>
          <li>Provide necessary documents, such as incident reports, photographs, and receipts.</li>
          <li>The insurer will assess the claim and provide a resolution based on policy terms.</li>
        </ol>
      ),
    },
    {
      question: "Why is Office Package Insurance important for businesses?",
      para:"This policy ensures:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Financial protection against unforeseen risks</li>
          <li>Business continuity in case of disruptions</li>
          <li>Compliance with lease or regulatory requirements</li>
          <li>Improved trust and credibility with clients and partners</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
      </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default OfficePackageInsuranceFaq;
