import React, { useState } from "react";
import { Link } from "react-router-dom";

const Mobiledropdownlogin = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const loginItems =[
    {label:'Employee', path:'https://corphelp.clearcover.in/login'},
    {label:'HR Login', path:'https://corphelp.clearcover.in/hr_login/login'},
    {label:'CRM Login', path:'https://corphelp.clearcover.in/crm-pages'},
  ]

  return (
    <div className="w-full overflow-y-auto">
      <div
        className="flex justify-center items-center cursor-pointer text-[16px] text-[#333] hover:text-[#61BC6D] px-3"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        Login
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="bg-[#fefefe] border-t border-gray-300 mt-3 overflow-y-auto">
          {loginItems.map((item, index) => (
            <div
              key={index}
              className="border-b border-gray-300 py-4 px-3"
            >
              <Link
                to={item.path}
                className="text-[14px] font-medium text-[#555] hover:text-[#61BC6D]"
              >
                {item.label}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Mobiledropdownlogin;
