import React from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';

const EscalationMatrix = () => {
    const primaryDetails = {
        name: "Harish T.",
        role: "Senior Manager - Corporate Relations",
        email: "harish@mdhinsurance.in",
        phone: "9553871111",
    };
    
    const abhishekDetails = {
        name: "Abhishek Shrivastava",
        role: "Head - Corporate Relations",
        email: "abhishek@mdhinsurance.in",
        phone: "7026371000",
    };
    

    return (
        <div className="my-14 max-w-4xl mx-auto p-8 bg-gradient-to-br from-white to-gray-100 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Escalation Matrix</h2>
            <p className="text-center mb-8 text-gray-600">
                For any issues or concerns, please refer to the escalation matrix below. We’re committed to resolving any concerns promptly.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="p-6 border border-gray-300 rounded-lg bg-gray-50 hover:shadow-md transition-shadow duration-300">
                    <h3 className="text-xl font-bold mb-3 text-gray-700">Primary Contact</h3>
                    <p><span className='font-semibold'>Name:</span> {primaryDetails.name}</p>
                    <p><span className='font-semibold'>Role:</span> {primaryDetails.role}</p>
                    <p className="flex items-center">
                        <FaEnvelope className="mr-2 text-blue-500" />
                        <a href={`mailto:${primaryDetails.email}`} className="text-blue-600 hover:underline">
                            {primaryDetails.email}
                        </a>
                    </p>
                    <p className="flex items-center">
                        <FaPhone className="mr-2 text-green-500" />
                        {primaryDetails.phone}
                    </p>
                </div>
                
                <div className="p-6 border border-gray-300 rounded-lg bg-gray-50 hover:shadow-md transition-shadow duration-300">
                    <h3 className="text-xl font-bold mb-3 text-gray-700">Secondary Contact</h3>
                    <p><span className='font-semibold'>Name:</span> {abhishekDetails.name}</p>
                    <p><span className='font-semibold'>Role:</span> {abhishekDetails.role}</p>
                    <p className="flex items-center">
                        <FaEnvelope className="mr-2 text-blue-500" />
                        <a href={`mailto:${abhishekDetails.email}`} className="text-blue-600 hover:underline">
                            {abhishekDetails.email}
                        </a>
                    </p>
                    <p className="flex items-center">
                        <FaPhone className="mr-2 text-green-500" />
                        {abhishekDetails.phone}
                    </p>
                </div>
            </div>

            <div className="mt-8 text-center">
                {/* <a href="#" className="inline-block bg-blue-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-blue-700 transition-colors duration-300">
                    View Full Escalation Matrix
                </a> */}
                <p className="inline-block bg-blue-600 text-white font-semibold px-6 py-3 rounded-md hover:bg-blue-700 transition-colors duration-300">
                    View Full Escalation Matrix
                </p>
            </div>
        </div>
    );
};

export default EscalationMatrix;
