import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import ProfessionalIndemnityInsuranceFaq from '../faq/ProfessionalIndemnityInsurance';
import { assets } from '../../assets/assets';

const piInsuranceImportance = [
    {
      title: 'Financial Protection',
      description: 'Legal claims can lead to significant financial losses, including court fees, settlements, and legal defense costs. PI insurance ensures you are financially protected.',
    },
    {
      title: 'Safeguards Reputation',
      description: 'A legal claim can harm your professional credibility. PI insurance provides resources to manage disputes and protect your reputation.',
    },
    {
      title: 'Client Confidence',
      description: 'Having PI insurance assures clients that you are prepared to handle liabilities, fostering trust and long-term business relationships.',
    },
    {
      title: 'Regulatory Compliance',
      description: 'For many professionals, PI insurance is a regulatory or contractual requirement. It demonstrates compliance and professionalism.',
    },
  ];
  const whoNeedsPIInsurance = [
    {
      title: 'Consultants',
      description: 'Management, business, and IT consultants often handle sensitive projects, making them vulnerable to claims of professional errors.',
    },
    {
      title: 'Healthcare Professionals',
      description: 'Doctors, nurses, and other medical practitioners require coverage for malpractice or negligence claims.',
    },
    {
      title: 'Architects and Engineers',
      description: 'Construction-related professionals face risks from design flaws, structural failures, or project delays.',
    },
    {
      title: 'Accountants and Financial Advisors',
      description: 'Errors in financial planning, tax advice, or accounting can lead to significant client losses and legal disputes.',
    },
    {
      title: 'Legal Professionals',
      description: 'Lawyers and solicitors need protection against claims arising from incorrect legal advice or missed deadlines.',
    },
    {
      title: 'Freelancers and Agencies',
      description: 'Creative professionals like designers, writers, and marketing consultants can face claims for intellectual property disputes or unsatisfactory work.',
    },
  ];
  const coverageByPIInsurance = [
    {
      title: 'Errors and Omissions',
      description: 'Protection against claims arising from mistakes, inaccuracies, or omissions in professional work.',
    },
    {
      title: 'Negligence',
      description: 'Covers claims related to failure to perform duties to the required standard.',
    },
    {
      title: 'Breach of Contract',
      description: 'Compensation for claims resulting from failure to fulfill contractual obligations.',
    },
    {
      title: 'Defamation',
      description: 'Protection against claims for libel, slander, or unintentional reputational harm.',
    },
    {
      title: 'Loss of Documents',
      description: 'Coverage for the cost of replacing or restoring lost or damaged client documents.',
    },
    {
      title: 'Legal Defense Costs',
      description: 'Covers expenses for legal representation, even if the claim is unfounded.',
    },
    {
      title: 'Intellectual Property Infringement',
      description: 'Protection against unintentional infringement of intellectual property rights during professional activities.',
    },
  ];

  const clearCoverBenefits = [
    {
      title: 'Comprehensive Coverage',
      description: 'Our policies cover a wide range of risks, ensuring holistic protection for professionals.',
    },
    {
      title: 'Customizable Plans',
      description: 'We tailor coverage to meet the specific needs of your profession or business.',
    },
    {
      title: 'Global Coverage',
      description: 'ClearCover offers worldwide protection, enabling professionals to operate confidently across borders.',
    },
    {
      title: 'Fast and Transparent Claims',
      description: 'Our streamlined claims process ensures quick and fair settlements, minimizing disruptions to your work.',
    },
    {
      title: 'Affordable Premiums',
      description: 'We provide cost-effective solutions without compromising on the quality of coverage.',
    },
  ];

  const howPIInsuranceWorks = [
    {
      title: 'Identify Your Risks',
      description: 'Evaluate the nature of your work, the industries you serve, and potential liabilities.',
    },
    {
      title: 'Choose Coverage Limits',
      description: 'Select appropriate coverage limits based on your business size, contracts, and client expectations.',
    },
    {
      title: 'Purchase a Policy',
      description: 'Work with ClearCover to customize your policy and ensure it aligns with your specific needs.',
    },
    {
      title: 'Respond to Claims',
      description: 'In case of a legal claim, notify ClearCover immediately. We’ll handle the legal defense and compensation, allowing you to focus on your work.',
    },
  ];
  
  const piInsuranceExclusions = [
    {
      title: 'Criminal Activities or Deliberate Misconduct',

    },
    {
      title: 'Non-Professional Liabilities',
    },
    {
      title: 'General Business Risks',
    },
    {
      title: 'Claims After Policy Period',
    },
    {
      title: 'Fines or Regulatory Penalties',
    },
  ];

  const claimProcess = [
    {
      title: 'Report the Incident',
      description: 'Notify ClearCover as soon as you become aware of a potential claim.',
    },
    {
      title: 'Submit Documentation',
      description: 'Provide required documents, such as the insurance policy, claim details, and any supporting evidence.',
    },
    {
      title: 'Assessment',
      description: 'Our team will assess the claim, verifying its validity and extent of coverage.',
    },
    {
      title: 'Claim Settlement',
      description: 'Once approved, we ensure prompt payment of legal fees and compensation, reducing your financial burden.',
    },
  ];
  const whyChooseClearCover = [
    {
      title: 'Industry Expertise',
      description: 'We have a deep understanding of the risks faced by professionals in various fields.',
    },
    {
      title: 'Dedicated Support',
      description: 'Our experienced team is available to assist you throughout the policy period, from purchase to claims.',
    },
    {
      title: 'Cutting-Edge Solutions',
      description: 'ClearCover leverages advanced tools and analytics to assess risks and offer personalized insurance plans.',
    },
    {
      title: 'Reputation Management',
      description: 'We provide resources to help you navigate client disputes and safeguard your professional credibility.',
    },
    {
      title: 'Trusted by Professionals',
      description: 'ClearCover is a preferred provider for thousands of professionals across diverse industries.',
    },
  ];
    
  
      

function ProfessionalIndeminityInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Professional Indemnity Insurance </h1>
    </div>
    {/* <img 
                    src={assets.professional} 
                    alt="Professional Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Professional Indemnity Insurance Policy  </h1>
        <p>Professional Indemnity Insurance (PI Insurance), also known as Errors and Omissions Insurance, is a critical safeguard for professionals and businesses that provide specialized services or advice. It offers financial protection against legal claims arising from errors, omissions, negligence, or breach of duty in the course of professional work.</p>

        <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we offer comprehensive Professional Indemnity Insurance tailored to protect your reputation, finances, and business continuity. Whether you're a consultant, architect, doctor, or IT professional, our policies ensure you’re prepared for unforeseen challenges.</p>

        <h1 className=' text-lg md:text-xl font-bold'>What is Professional Indemnity Insurance Policy? </h1>
        <p>Professional Indemnity Insurance is a liability insurance policy designed to cover legal costs and damages incurred from claims made by clients or third parties for professional errors or negligence. It is essential for any profession where advice, design, or services are provided to clients, especially when financial or reputational stakes are involved.</p>

        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Why is Professional Indemnity Insurance Important?</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {piInsuranceImportance.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Professional Indemnity Insurance?</h2>
                    <p>Professional Indemnity Insurance is essential for individuals and businesses providing specialized advice or services, including:</p>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whoNeedsPIInsurance.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Coverage Provided by Professional Indemnity Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {coverageByPIInsurance.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of ClearCover Professional Indemnity Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {clearCoverBenefits.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Does Professional Indemnity Insurance Work?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howPIInsuranceWorks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">What Does Professional Indemnity Insurance Not Cover? </h2>
                    <p>While PI insurance offers comprehensive protection, it typically excludes:</p>
                    <ol className="flex flex-col gap-4 list-disc list-inside ml-4">
                        {piInsuranceExclusions.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md ">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Claim Process</h2>
                    <p>Implementing a Group Health Insurance Policy with ClearCover is simple and efficient: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for Professional Indemnity Insurance?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>
                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Partner with ClearCover for Peace of Mind</h2>
                    <p>Professional Indemnity Insurance is a crucial tool for protecting your career and business from unexpected challenges. At ClearCover, we’re committed to providing tailored, comprehensive solutions to meet the unique needs of professionals across industries.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>Professional Indemnity Insurance quote</span> or speak with our experts at <span className='font-bold'>+91 9133309629</span>. </p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs About Professional Indemnity Insurance</h1>
                    <ProfessionalIndemnityInsuranceFaq/>
                </div>
                
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default ProfessionalIndeminityInsurance