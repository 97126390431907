import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import { assets } from '../../assets/assets'
import Faq from './Faq';

// Reusable data for the list items
const insuranceBenefits = [
    {
      title: 'Comprehensive Coverage',
      description: 'Covers hospitalization, pre- and post-treatment care, outpatient services, and wellness check-ups.',
    },
    {
      title: 'Customizable Plans',
      description: 'Flexible solutions that fit your organization’s budget while addressing employee health needs.',
    },
    {
      title: 'Seamless Claims Process',
      description: 'A simplified, hassle-free claims procedure ensures minimal disruptions for your employees.',
    },
    {
      title: 'Additional Benefits',
      description: 'Options like maternity care, dental coverage, and mental health support for enhanced employee satisfaction.',
    },
    {
      title: 'Dedicated 24/7 Support',
      description: 'Round-the-clock assistance to resolve queries and provide guidance whenever needed.',
    },
  ];

const keyFeatures = [
    {
      title: 'Cashless Hospitalization',
      description: 'Employees can access a wide network of hospitals for treatment without the need for upfront payment.',
    },
    {
      title: 'Coverage for Pre-Existing Conditions',
      description: 'Includes pre-existing medical conditions after a stipulated waiting period, ensuring long-term care.',
    },
    {
      title: 'Flexible Sum Insured',
      description: 'Employers can choose coverage levels to meet organizational and employee expectations.',
    },
    {
      title: 'Maternity and Newborn Benefits',
      description: 'Special provisions for maternity care and newborn coverage from birth.',
    },
    {
      title: 'Preventive Health Check-Ups',
      description: 'Encourages proactive health management through regular screenings.',
    },
    {
        title: 'Mental Health Support',
        description: 'Offers coverage for mental health consultations, therapies, and counselling sessions.',
    },
  ];

  const advantages = [
    {
      title: 'Employee Satisfaction and Morale',
      description: 'Demonstrates your commitment to employee well-being, creating a happier and more engaged workforce.',
    },
    {
      title: 'Talent Acquisition and Retention',
      description: 'Competitive benefits like health insurance make your organization attractive to top talent.',
    },
    {
      title: 'Tax Benefits for Employers',
      description: 'Premiums paid for Group Health Insurance are often tax-deductible, offering financial advantages.',
    },
    {
      title: 'Enhanced Productivity',
      description: 'Healthy employees are less likely to take sick leaves and are more focused and efficient.',
    },
    {
      title: 'Cost-Effectiveness',
      description: 'Group health insurance is more affordable than individual plans, offering extensive coverage at reduced costs.',
    },
  ];

  const coverageDetails = [
    {
      title: 'Full-Time Employees',
      description: 'Permanent employees form the primary group covered under the policy.',
    },
    {
      title: 'Dependents',
      description: 'Spouses, children, and in some cases, parents of employees can also be included.',
    },
    {
      title: 'Part-Time or Contractual Staff',
      description: 'Depending on the policy, coverage can extend to part-time or contract-based employees, enhancing inclusivity.',
    },
  ];

  const addOns = [
    {
      title: 'Critical Illness Cover',
      description: 'Lump sum payout for life-threatening conditions such as cancer or organ failure.',
    },
    {
      title: 'Accidental Death and Disability Benefits',
      description: 'Financial assistance for accidents resulting in death or disability.',
    },
    {
      title: 'Daily Hospital Cash',
      description: 'Fixed daily payouts during hospitalization to cover additional expenses.',
    },
    {
      title: 'OPD (Outpatient Department) Coverage',
      description: 'Reimbursement for outpatient consultations, tests, and prescribed medications.',
    },
    {
      title: 'Wellness Programs',
      description: 'Access to fitness programs, health workshops, and wellness apps to promote a healthier lifestyle.',
    },
  ];

  const investments = [
    {
      title: 'Boosts Employer Branding',
      description: 'Projects a positive image of your company as an organization that values its people.',
    },
    {
      title: 'Reduces Attrition Rates',
      description: 'Employees are more likely to stay with companies that prioritize their health and well-being.',
    },
    {
      title: 'Decreases Absenteeism',
      description: 'Healthy employees take fewer sick leaves, resulting in consistent productivity.',
    },
    {
      title: 'Enhances Organizational Culture',
      description: 'Demonstrates care and empathy, fostering a strong and supportive workplace environment.',
    },
    {
      title: 'Future-Proofing Your Workforce',
      description: 'Encourages a healthier workforce, reducing long-term medical expenses and liabilities.',
    },
  ];

  const steps = [
    {
      title: 'Reach Out',
      description: 'Connect with our team via our website or helpline to discuss your needs.',
    },
    {
      title: 'Define Your Requirements',
      description: 'Share details about your workforce size, industry, and coverage preferences.',
    },
    {
      title: 'Receive a Quote',
      description: 'Get a customized group health insurance quote with flexible plan options tailored to your organization.',
    },
    {
      title: 'Policy Onboarding',
      description: 'Seamless onboarding with clear communication to your employees about the policy benefits.',
    },
    {
      title: 'Continuous Support',
      description: 'Dedicated account managers ensure smooth policy management and claims processing.',
    },
  ];

  const additionalPerks = [
    {
      title: 'Employee Wellness Programs',
      description: 'Access to fitness classes, diet consultations, and health challenges to promote overall well-being.',
    },
    {
      title: 'Digital Health Records',
      description: 'Secure and easy access to medical records for better healthcare management.',
    },
    {
      title: 'Telemedicine Services',
      description: 'Round-the-clock virtual consultations with doctors for timely medical advice.',
    },
    {
      title: 'Customized Alerts',
      description: 'Regular updates on health benefits, claims status, and upcoming health check-ups.',
    },
  ];

  const whyClearCover = [
    {
      title: 'Tailored Policies',
      description: 'Our plans are flexible and designed to suit the diverse needs of organizations across industries.',
    },
    {
      title: 'Extensive Network',
      description: 'Access to a wide range of partner hospitals and healthcare providers nationwide.',
    },
    {
      title: 'Transparent Processes',
      description: 'Clear communication and straightforward claims processes minimize employee concerns.',
    },
    {
      title: 'Proactive Approach',
      description: 'Focus on preventive care to avoid medical emergencies and promote overall wellness.',
    },
  ];
  

// #eeecec
function GroupHealthInsurance() {
    return (<>
        <div className="">
              {/* <img 
                src={assets.groupHealth} 
                alt="Group Health Insurance" 
                style={{ width: "100%", height:"", objectFit: "cover" }} 
              /> */}
            <div className="py-20 flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]"
                //   style={{
                //     backgroundImage: `url(${assets.inurance_banner})`,
                //     backgroundSize: "cover",
                //     backgroundPosition: "center"}}
                    >
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Group Health Insurance</h1>
            </div>

            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>Group Health Insurance Policy </h1>
                <p>A healthy and happy workforce is the cornerstone of organizational success. By providing your employees with a robust <span className='font-bold'>Group Health Insurance Policy</span> , you’re not just ensuring their well-being- you’re fostering loyalty, productivity, and trust. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we recognize the value of safeguarding your team’s health and offer <span className='font-bold'> customized group health insurance solutions </span>that cater to the unique needs of your organization.
                </p>

                <h1 className=' text-lg md:text-xl font-bold'>What is Group Health Insurance Policy?</h1>
                <p>Group Health Insurance is a cost-effective and convenient policy that covers a group of employees under a single plan. Typically offered as a core component of employee benefits, this insurance provides financial security for medical expenses related to illnesses, injuries, or hospitalizations. By pooling resources, employers can secure competitive rates and better coverage for their teams, reinforcing their commitment to employee welfare.
                </p>

                
                <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Why Choose ClearCover for Group Health Insurance?</h1>
                    <p>ClearCover goes beyond traditional health insurance, offering tailored plans with features designed to meet both employer and employee needs. Here’s why <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> stands out: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {insuranceBenefits.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Key Features of ClearCover’s Group Health Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {keyFeatures.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Advantages of Offering Group Health Insurance</h2>
                    <p>Providing Group Health Insurance yields numerous benefits for both employers and employees: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {advantages.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Can Be Covered Under Group Health Insurance?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {coverageDetails.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Covers to Amplify Protection</h2>
                    <p>ClearCover offers optional add-ons to provide extended coverage for your workforce, ensuring every health need is addressed:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {addOns.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Group Health Insurance is a Strategic Investment </h2>
                    <p>Investing in employee group health insurance policy is more than just a regulatory necessity—it’s a strategic decision that directly impacts organizational success: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {investments.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How to Get Started with ClearCover </h2>
                    <p>Implementing a Group Health Insurance Policy with ClearCover is simple and efficient: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {steps.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Additional Perks with ClearCover Group Health Insurance </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {additionalPerks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why ClearCover is the Best Choice for Your Organization </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Workforce Today</h2>
                    <p>Prioritizing your employees’ health reflects your company’s values and fosters a culture of care and productivity. With ClearCover’s <span className='font-bold'>Group Health Insurance Policy</span>, you’re not just offering a benefit - you’re investing in the long-term success of your organization.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>Group Health Insurance</span>  quote or speak with our experts at <span className='font-bold'>+91 9133309629</span>. Ensure your workforce’s health and happiness, and watch your organization thrive!</p>



                </div>

            <div className="text-[14px]">
                <h1 className=' text-lg md:text-xl font-bold'>FAQs on Employee Group Health Insurance Policy</h1>
                <Faq/>
            </div>

            </div>

            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
    </>)
}

export default GroupHealthInsurance