import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const PlantMachineryInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Plant and Machinery Insurance?",
      para:"",
      answer:
        "Plant and Machinery Insurance provides coverage for accidental damages, breakdowns, or losses to equipment and machinery used in various industries, such as construction, manufacturing, and agriculture. It ensures financial protection and operational continuity.",
    },
    {
      question: "What types of equipment are covered under this policy?",
      para:"The policy covers a wide range of equipment, including:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Construction machinery (e.g., cranes, bulldozers)</li>
          <li>Manufacturing equipment</li>
          <li>Agricultural tools (e.g., tractors, harvesters)</li>
          <li>Fixed installations at plants or factories</li>
        </ul>
      ),
    },
    {
      question: "What risks does Plant and Machinery Insurance cover?",
      para:"The policy typically covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Accidental damages</li>
          <li>Theft or burglary</li>
          <li>Fire and explosion</li>
          <li>Natural disasters (e.g., floods, earthquakes)</li>
          <li>Mechanical or electrical breakdowns</li>
          <li>Third-party liabilities</li>
        </ul>
      ),
    },
    {
      question: "What is not covered under this policy?",
      para:"Common exclusions include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Wear and tear due to regular use</li>
          <li>Wilful negligence or improper handling</li>
          <li>Pre-existing damages or faults</li>
          <li>Losses due to war or nuclear risks</li>
          <li>Deliberate misuse of equipment</li>
        </ul>
      ),
    },
    {
      question: "Who should purchase Plant and Machinery Insurance?",
      para:"The policy is ideal for:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Construction companies</li>
          <li>Manufacturing units</li>
          <li>Agricultural enterprises</li>
          <li>Mining and quarrying industries</li>
          <li>Small and medium-sized businesses relying on machinery</li>
        </ul>
      ),
    },
    {
      question: "Can mobile equipment be insured?",
      answer:
        "Yes, mobile equipment such as excavators, forklifts, and cranes can be insured under Contractor’s Plant and Machinery Insurance. This provides coverage for damages during operations or transportation.",
    },
    {
      question: "Does the policy cover machinery during transit?",
      answer:
        "Many policies include transit coverage to protect machinery being transported between sites. Additional coverage can also be purchased for enhanced protection.",
    },
    {
      question: "How are premiums calculated for this insurance?",
      para:"Premiums depend on several factors, including:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>The type and value of the machinery</li>
          <li>Coverage type and extent</li>
          <li>Operational risks and usage environment</li>
          <li>Age and condition of the equipment</li>
        </ul>
      ),
    },
    {
      question: "What is the process to file a claim?",
      para:"To file a claim:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify the insurer immediately about the damage or loss.</li>
          <li>Provide necessary documents, such as the insurance policy, equipment details, and incident report.</li>
          <li>Allow the insurer to assess the damage.</li>
          <li>Receive compensation based on the policy terms.</li>
        </ol>
      ),
    },
    {
      question: "Can the policy be customized for specific needs?",
      answer:
        "Yes, most Plant and Machinery Insurance policies can be customized to include specific coverage options, such as transit insurance, third-party liability, or extended coverage for additional risks.",
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
              <span>{faq.para}</span>
              <div
                id={`faq-answer-${index}`}
                className="faq-answer mt-2 text-[#555] transition-all duration-300"
              >
                {faq.answer}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PlantMachineryInsuranceFaq;
