import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const FireAndBurglaryFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Fire and Burglary Insurance?",
      answer:
        "Fire and Burglary Insurance is a policy that protects against financial losses caused by fire-related damages and burglary incidents. It covers property, inventory, and valuable assets.",
    },
    {
      question: "Who can benefit from Fire and Burglary Insurance?",
      answer:
        "This insurance is ideal for businesses such as retail stores, warehouses, offices, and homeowners who want to safeguard their properties and assets.",
    },
    {
      question: "What does Fire and Burglary Insurance cover?",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Fire damage</li>
          <li>Theft and forced entry</li>
          <li>Loss of stock or inventory</li>
          <li>Damage to property</li>
          <li>Optional add-ons like debris removal or alternate accommodation</li>
        </ul>
      ),
    },
    {
      question: "Are natural calamities covered under this policy?",
      answer:
        "Yes, many policies offer optional coverage for natural disasters like floods, earthquakes, storms, and cyclones.",
    },
    {
      question: "What are the exclusions under Fire and Burglary Insurance?",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Intentional damage</li>
          <li>Damages due to war or nuclear risks</li>
          <li>Losses resulting from negligence or unreported incidents</li>
        </ul>
      ),
    },
    {
      question: "How is the premium for Fire and Burglary Insurance calculated?",
      answer:
        "The premium depends on factors like the property’s location, value of insured assets, type of coverage, add-ons, and risk assessment.",
    },
    {
      question: "Can I extend the coverage to include machinery and equipment?",
      answer:
        "Yes, you can add coverage for machinery breakdown or equipment protection as part of the policy’s add-ons.",
    },
    {
      question: "What is the claims process for Fire and Burglary Insurance?",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Inform the insurer immediately in case of an incident.</li>
          <li>Provide necessary documents (e.g., FIR for burglary, fire brigade report).</li>
          <li>File a claim for assessment and settlement.</li>
        </ol>
      ),
    },
    {
      question: "Does the policy cover third-party liabilities?",
      answer:
        "Yes, some policies include third-party liability coverage for damages caused to third-party property due to fire incidents.",
    },
    {
      question: "Why is Fire and Burglary Insurance important for businesses?",
      answer:
        "It helps businesses mitigate risks, ensures continuity, and provides financial security against unforeseen losses, protecting both assets and operations.",
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FireAndBurglaryFaq;
