import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import OfficePackageInsuranceFaq from '../faq/OfficePackageInsuranceFaq';
import { assets } from '../../assets/assets';

const whyChooseClearCover = [
    { title: "Comprehensive Coverage", description: "Covers property damage, liabilities, theft, and more." },
    { title: "Tailored Solutions", description: "Flexible policies to suit your industry and business size." },
    { title: "Affordable Premiums", description: "Cost-effective plans that provide maximum value." },
    { title: "Streamlined Claims Process", description: "Hassle-free claims management for quick resolutions." },
    { title: "24/7 Support", description: "Dedicated customer service to address your queries anytime." }
  ];
  const keyFeatures = [
    { title: "Property Damage Protection", description: "Coverage for damages due to fire, explosions, natural disasters, or other unforeseen events." },
    { title: "Burglary and Theft Coverage", description: "Compensation for losses or damages to office assets, including equipment, furniture, and valuable documents." },
    { title: "Electronic Equipment Insurance", description: "Covers accidental damages to electronic devices such as computers, printers, servers, and other office equipment." },
    { title: "Business Interruption Cover", description: "Financial support for income loss due to temporary closure resulting from covered risks." },
    { title: "Third-Party Liability Protection", description: "Safeguards your business from legal liabilities arising from accidental injuries or property damage caused to third parties." },
    { title: "Employee Benefits Coverage", description: "Includes coverage for employee-related liabilities such as workplace accidents or injuries." },
    { title: "Money Insurance", description: "Protection against the loss of cash within office premises or during transit." },
    { title: "Glass Breakage Cover", description: "Compensation for accidental breakage of glass panels, windows, or doors." }
  ];
  const benefits = [
    { title: "All-in-One Policy", description: "Consolidates multiple insurance needs under one plan, simplifying policy management." },
    { title: "Financial Security", description: "Mitigates financial losses due to unforeseen risks." },
    { title: "Business Continuity", description: "Ensures uninterrupted operations even after adverse events." },
    { title: "Peace of Mind", description: "Focus on growing your business without worrying about unexpected liabilities." },
    { title: "Improved Credibility", description: "Builds trust with clients and stakeholders by demonstrating a proactive risk management approach." }
  ];
  const addOnCoverages = [
    { title: "Terrorism Cover", description: "Protects against damages caused by acts of terrorism." },
    { title: "Valuable Document Cover", description: "Coverage for the loss or damage of important business documents." },
    { title: "Tenant Liability Insurance", description: "Protects against liabilities arising as a tenant of the office space." },
    { title: "Natural Disaster Coverage", description: "Includes protection against floods, cyclones, and earthquakes." },
    { title: "Portable Equipment Insurance", description: "Covers portable equipment like laptops, tablets, and projectors used outside the office premises." }
  ];
  const suitableBusinesses = [
    { title: "Small and Medium Enterprises (SMEs)", description: "Protection for startups, small offices, and growing businesses." },
    { title: "Corporates", description: "Comprehensive coverage for large office setups." },
    { title: "Retail Businesses", description: "Safeguard retail spaces and inventories." },
    { title: "Co-Working Spaces", description: "Protection for shared office setups and assets." },
    { title: "Freelancers and Professionals", description: "Tailored plans for home-based businesses or individual professionals." }
  ];
  const howInsuranceWorks = [
    { title: "Policy Selection", description: "Choose a policy that fits your business needs, with options for customization." },
    { title: "Risk Assessment", description: "The insurer evaluates the risks associated with your office premises, location, and operations." },
    { title: "Premium Payment", description: "Pay the premium based on the coverage and add-ons selected." },
    { title: "Claim Filing", description: "In case of an incident, file a claim by reporting the event and submitting necessary documents." },
    { title: "Claim Settlement", description: "Once the claim is verified, the insurer compensates for the losses or damages." }
  ];
  const smartInvestmentBenefits = [
    { title: "Mitigates Risks", description: "Reduces the financial impact of unexpected events." },
    { title: "Ensures Legal Compliance", description: "Many leases and contracts require liability insurance." },
    { title: "Protects Reputation", description: "Quick recovery from losses maintains client trust and business reputation." },
    { title: "Cost-Effective", description: "Bundled coverage reduces the need for multiple policies, saving costs." },
    { title: "Customizable Coverage", description: "Tailor the policy to match your specific business requirements." }
  ];
  const stepsToGetStarted = [
    { title: "Contact Us", description: "Reach out via our website or helpline to discuss your needs." },
    { title: "Risk Evaluation", description: "Provide details about your office, assets, and operations for a risk assessment." },
    { title: "Receive a Quote", description: "Get a customized quote with flexible plan options." },
    { title: "Policy Activation", description: "Complete the documentation and activate your policy." },
    { title: "Ongoing Support", description: "Enjoy continuous assistance for claims and policy management." }
  ];
 
  const additionalPerks = [
    { title: "Wide Network of Partners", description: "Access to a vast network of repair and service providers." },
    { title: "Digital Policy Management", description: "Use our online tools for easy policy tracking and renewals." },
    { title: "24/7 Claims Assistance", description: "Round-the-clock support to ensure quick resolutions." },
    { title: "Preventive Risk Assessments", description: "Expert guidance on mitigating potential risks." },
    { title: "Customer-Centric Approach", description: "Tailored solutions that prioritize your business’s unique needs." }
  ];
  

function OfficePackagePolicy() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Office Package Policy  </h1>
            </div>
            {/* <img 
                            src={assets.office} 
                            alt="Office Package Insurance" 
                            style={{ width: "100%", height: "", objectFit: "cover" }} 
                          /> */}
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>Office Package Insurance Policy  </h1>
                <p> Running a business involves navigating numerous risks, from property damage to unexpected liabilities. The <span className='font-bold'>Office Package Insurance Policy</span> by <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> provides a robust shield for your office premises, ensuring business continuity and peace of mind. This all-in-one solution is designed to safeguard office properties, assets, and operations against a wide range of unforeseen events. </p>


                <h1 className=' text-lg md:text-xl font-bold'>What is Office Package Insurance Policy?</h1>
                <p><span className='font-bold'>Office Package Insurance Policy</span> is a comprehensive insurance solution tailored for businesses to protect their office premises, assets, and employees. It consolidates multiple insurance coverages under a single policy, offering protection against risks like fire, burglary, natural disasters, accidental damages, and third-party liabilities.</p>

                <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Why Choose ClearCover for Office Package Insurance Policy?</h1>
                    <p><a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> offers customized Office Package Insurance policies to meet the specific needs of your business. Here’s why we stand out: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCover.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Key Features of ClearCover’s Office Package Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {keyFeatures.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of Office Package Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {benefits.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Coverages for Enhanced Protection</h2>
                    <p>ClearCover offers a range of add-ons to extend your Office Package Insurance coverage:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {addOnCoverages.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Office Package Insurance?</h2>
                    <p>This insurance policy is suitable for:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {suitableBusinesses.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Office Package Insurance Works</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howInsuranceWorks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Office Package Insurance is a Smart Investment </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {smartInvestmentBenefits.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Steps to Get Started with ClearCover’s Office Package Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {stepsToGetStarted.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Additional Perks with ClearCover</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {additionalPerks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Why ClearCover?</h2>
                    <p>With ClearCover, you’re not just getting an office package policy — you’re investing in the long-term security of your business. Our Office Package Insurance policies are designed to address the specific risks businesses face, ensuring comprehensive protection and minimal disruptions.</p>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Business Today</h2>
                    <p>Protecting your office premises and operations is crucial for sustaining growth and success. With ClearCover’s <span className='font-bold'> Office Package Insurance Policy</span>, you can secure your assets, ensure business continuity, and focus on achieving your goals.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>Office Package Insurance</span>  quote or speak with our experts at <span className='font-bold'>+91 9133309629</span>. Take the first step towards safeguarding your business with ClearCover!</p>
                </div>
                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs on Office Package Insurance Policy</h1>
                    <OfficePackageInsuranceFaq/>
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default OfficePackagePolicy