import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import GeneralLiabilityFaq from '../faq/GeneralLiabilityFaq';
import { assets } from '../../assets/assets';

const generalLiabilityInsuranceFeatures = [
    {
      title: "Third-Party Bodily Injury",
      description: "Covers medical expenses and legal costs if someone is injured on your business premises or due to your operations."
    },
    {
      title: "Property Damage",
      description: "Provides compensation for damages to third-party property caused by your business activities."
    },
    {
      title: "Personal and Advertising Injury",
      description: "Covers claims related to defamation, libel, slander, or copyright infringement in advertising or marketing efforts."
    },
    {
      title: "Legal Defense Costs",
      description: "Covers the cost of hiring lawyers, court fees, and other legal expenses."
    },
    {
      title: "Worldwide Coverage Options",
      description: "Extends coverage for claims arising from business operations conducted globally."
    },
    {
      title: "Customizable Plans",
      description: "Tailored to meet the specific needs and risks of your industry."
    }
  ];

const reasonsForGeneralLiability = [
    {
      title: "Legal Compliance",
      description: "In many industries, liability insurance is mandatory to comply with regulatory requirements."
    },
    {
      title: "Protection Against Lawsuits",
      description: "Reduces financial risks from unexpected legal claims or lawsuits."
    },
    {
      title: "Enhanced Business Credibility",
      description: "Demonstrates professionalism and reliability to clients and partners."
    },
    {
      title: "Comprehensive Coverage",
      description: "Provides peace of mind by covering a broad spectrum of risks."
    },
    {
      title: "Cost-Effective Risk Management",
      description: "Avoids significant financial burdens arising from unforeseen liabilities."
    }
  ];
  
  // What Does General Liability Insurance Cover?
const liabilityInsuranceCoverage = [
    {
      title: "Bodily Injury",
      description: "Accidental injuries to third parties, such as customers or visitors, on your premises or due to your business operations."
    },
    {
      title: "Property Damage",
      description: "Covers repairs or replacement costs for damage caused to a third party’s property."
    },
    {
      title: "Personal and Advertising Injury",
      description: "Protection against claims of defamation, copyright infringement, or false advertising."
    },
    {
      title: "Medical Payments",
      description: "Immediate medical expense coverage for injured third parties without determining fault."
    },
    {
      title: "Legal Defense",
      description: "Financial support for legal representation, settlements, and court judgments."
    }
  ];

// Who Needs General Liability Insurance?
const businessesThatNeedLiabilityInsurance = [
    {
      title: "Small Businesses",
      description: "Retail shops, restaurants, salons, etc."
    },
    {
      title: "Corporates",
      description: "Large enterprises across various sectors."
    },
    {
      title: "Contractors",
      description: "Builders, electricians, and plumbers who work on-site."
    },
    {
      title: "Professional Services",
      description: "Consultants, designers, and marketing agencies."
    },
    {
      title: "Manufacturers",
      description: "Businesses producing goods prone to defects or accidents."
    },
    {
      title: "Event Organizers",
      description: "Coverage for public events, conferences, or trade shows."
    }
  ];

const generalLiabilityAddOns = [
    {
      title: "Product Liability Insurance",
      description: "Covers claims related to defective products."
    },
    {
      title: "Professional Liability Insurance",
      description: "Protection against errors, omissions, or negligence in professional services."
    },
    {
      title: "Cyber Liability Insurance",
      description: "Addresses risks related to data breaches or cyberattacks."
    },
    {
      title: "Umbrella Liability Insurance",
      description: "Provides additional coverage limits over primary policies."
    }
  ];

// How to Choose the Right General Liability Insurance Coverage?
const chooseRightCoverage = [
    {
      title: "Assess Your Business Risks",
      description: "Identify potential liability risks unique to your industry and operations."
    },
    {
      title: "Determine Coverage Limits",
      description: "Choose a policy with adequate coverage to handle possible claims and legal expenses."
    },
    {
      title: "Review Exclusions",
      description: "Understand what is not covered under the policy to avoid surprises."
    },
    {
      title: "Compare Providers",
      description: "Evaluate multiple insurers for pricing, benefits, and customer service."
    },
    {
      title: "Consult Experts",
      description: "Work with ClearCover professionals to customize your policy."
    }
  ];
  
  // Why Choose ClearCover for General Liability Insurance Coverage?
const whyChooseClearCoverForLiability = [
    {
      title: "Industry Expertise",
      description: "We understand the specific risks businesses face and provide tailored solutions."
    },
    {
      title: "Comprehensive Coverage",
      description: "Policies designed to address diverse liability risks effectively."
    },
    {
      title: "Affordable Premiums",
      description: "Cost-effective plans to suit businesses of all sizes."
    },
    {
      title: "Hassle-Free Claims",
      description: "Quick and transparent claims process to minimize disruptions."
    },
    {
      title: "Dedicated Support",
      description: "Our team is available 24/7 to address your concerns and guide you."
    }
  ];
  
  // How Does the Claims Process Work?
const claimsProcess = [
    {
      title: "Report the Incident",
      description: "Inform ClearCover immediately about the claim with relevant details."
    },
    {
      title: "Submit Documentation",
      description: "Provide necessary documents such as incident reports, photographs, and legal notices."
    },
    {
      title: "Claim Assessment",
      description: "Our claims team reviews the information and assesses the validity of the claim."
    },
    {
      title: "Settlement",
      description: "ClearCover ensures prompt settlement to reduce financial and operational impact."
    }
  ];

  const exclusionsForGeneralLiabilityInsurance = [
    {
      content: [
        "Employee Injuries or Illnesses (Covered Under Workers’ Compensation Insurance)",
        "Professional Errors or Negligence (Covered Under Professional Liability Insurance)",
        "Intentional Damage or Illegal Activities",
        "Damage to Your Own Business Property"
      ]
    }
  ];
  
const generalLiabilityInsuranceBenefits = [
    {
      title: "Protects Financial Assets",
      description: "Prevents unexpected liabilities from draining your business finances."
    },
    {
      title: "Boosts Client Confidence",
      description: "Enhances credibility, making your business more attractive to clients and partners."
    },
    {
      title: "Ensures Business Continuity",
      description: "Safeguards against risks that could disrupt operations."
    },
    {
      title: "Encourages Growth",
      description: "Allows you to focus on expanding your business without worrying about liabilities."
    }
  ];

  const getStartedWithGeneralLiability = [
    {
      step: 1,
      title: "Request a Quote",
      description: "Use our online tool or contact our team for a personalized quote."
    },
    {
      step: 2,
      title: "Customize Your Policy",
      description: "Choose coverage and add-ons that match your business needs."
    },
    {
      step: 3,
      title: "Activate Your Coverage",
      description: "Complete the formalities and secure your business."
    }
  ];
  
  
  

function GeneralLiabilityInsurance() {
  return (
    <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>General Liability Insurance  </h1>
            </div>

            {/* <img 
                            src={assets.general} 
                            alt="General Insurance" 
                            style={{ width: "100%", height: "", objectFit: "cover" }} 
                          /> */}
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>General Liability Insurance Policy  </h1>
                <p>Running a business comes with its share of risks, including potential liabilities arising from third-party claims. <span className='font-bold'>General Liability Insurance Policy</span> is a comprehensive solution designed to protect businesses against financial losses caused by third-party bodily injury, property damage, or legal actions. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we provide tailored General Liability Insurance policies to ensure your business remains resilient in the face of unexpected challenges. </p>


                <h1 className=' text-lg md:text-xl font-bold'>What is General Liability Insurance Policy?</h1>
                <p>General Liability Insurance is a policy that offers financial protection to businesses against claims of:</p>
                <ul className='flex flex-col gap-3 list-inside list-disc ml-5'>
                   <li>Bodily injury to third parties</li>
                   <li>Property damage caused by business operations</li>
                   <li>Personal or advertising injury, such as defamation or copyright infringement</li>
                </ul>
                <p>This policy covers legal defense costs, settlements, and judgments, safeguarding your business's finances and reputation.</p>

                 
                <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Key Features of General Liability Insurance Policy</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {generalLiabilityInsuranceFeatures.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Do Businesses Need General Liability Insurance?</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {reasonsForGeneralLiability.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">What Does General Liability Insurance Cover?</h2>
                    <p>Providing Group Health Insurance yields numerous benefits for both employers and employees: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {liabilityInsuranceCoverage.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs General Liability Insurance?</h2>
                    <p>General Liability Insurance is essential for all types of businesses, including:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {businessesThatNeedLiabilityInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Coverages for Enhanced Protection</h2>
                    <p><a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> offers optional coverages to broaden the scope of your General Liability Insurance:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {generalLiabilityAddOns.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How to Choose the Right General Liability Insurance Coverage? </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {chooseRightCoverage.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for General Liability Insurance Coverage?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCoverForLiability.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Does the Claims Process Work? </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimsProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">General Liability Insurance Exclusions</h2>
                    <p>While General Liability Insurance provides broad protection, it typically does not cover:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                    {exclusionsForGeneralLiabilityInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                        <ul className="list-disc list-inside ml-4 gap-2">
                            {coverage.content.map((item, i) => (
                            <div key={i} className="flex flex-row gap-1">
                                <li className="text-[17px] mt-2"></li>
                                <p className="text-[15px] mt-2">{item}</p>
                            </div>
                            ))}
                        </ul>
                        </div>
                    ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why General Liability Insurance is a Smart Investment</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {generalLiabilityInsuranceBenefits.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Get Started with ClearCover’s General Liability Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {getStartedWithGeneralLiability.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Business Against Unforeseen Risks!</h2>
                    <p>General Liability Insurance is a cornerstone of risk management for businesses of all sizes. With ClearCover, you can protect your company from financial and reputational damage caused by third-party claims, allowing you to focus on growth and success.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'> general liability insurance quote</span> or speak with our experts at <span className='font-bold'>+91 9133309629</span>.</p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs on General Liability Insurance</h1>
                    <GeneralLiabilityFaq/>
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
  )
}

export default GeneralLiabilityInsurance