import React,{useState} from 'react'
import { assets } from '../assets/assets';

function GetAQuote() {
  const [message, setMessage] = useState(''); 
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      service:''
    });

    const [errors, setErrors] = useState({
      name: "",
      email: "",
      phone: "",
      service: "",
    });

    // Validation functions
  const nameValidation = (name) => {
   if (!name.trim()) return "Name is required.";
   if (name.length < 3) return "Name must be at least 3 characters.";
   if (!/^[A-Za-z\s]+$/.test(name)) return 'Only letters and spaces allowed';
   if (name.length > 50) return "Name cannot exceed 50 characters.";
   if (/\s{2,}/.test(name)) return "Name cannot contain consecutive spaces.";
   if (/^\s|\s$/.test(name)) return "Name cannot start or end with a space.";
   return "";
 };

 const emailValidation = (email) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
   const consecutiveDotsRegex = /\.{2,}/;
   const domainPart = email.split('@')[1];
 
   // if (!email.trim()) return 'Email is required';
   if (!emailRegex.test(email)) return 'Invalid email format (e.g., mail@example.com)';
   if (consecutiveDotsRegex.test(email))
     return 'Email address cannot contain consecutive dots';
   if (email.startsWith('.') || email.endsWith('.'))
     return 'Email address cannot start or end with a dot';
   if (!domainPart || domainPart.split('.').length < 2)
     return 'Email must include a valid domain (e.g., example.com)';
   if (domainPart.split('.')[0].length < 2)
     return 'Domain part of email must be valid (e.g., example.com)';
 
   // Known domain validation
   const knownDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com'];
   const domain = domainPart.toLowerCase();
   if (!knownDomains.some((d) => domain.endsWith(d))) {
     return 'Email domain is not recognized. Use a common email provider.';
   }
 
   return '';
 };

 const phoneValidation = (phone) => {
   const phoneRegex = /^[0-9]{10}$/;
   if (!phone.trim()) return "Phone number is required.";
   if (!phoneRegex.test(phone)) return "Phone number must be 10 digits.";
   return "";
 };

 const serviceValidation = (service) => {
   if (!service) return "Please select a service.";
   return "";
 };


  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));

      let error = "";
      if (name === "name") error = nameValidation(value);
      if (name === "email") error = emailValidation(value);
      if (name === "phone") error = phoneValidation(value);
      if (name === "service") error = serviceValidation(value);
  
      setErrors((prev) => ({
        ...prev,
        [name]: error,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const nameError = nameValidation(formData.name);
      const emailError = emailValidation(formData.email);
      const phoneError = phoneValidation(formData.phone);
      const serviceError = serviceValidation(formData.service);

      if (nameError || emailError || phoneError || serviceError) {
         setErrors({
         name: nameError,
         email: emailError,
         phone: phoneError,
         service: serviceError,
         });
         return;
      }

      // Create FormData and include file
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("service", formData.service);

    // Add the image file
    const imageElement = document.querySelector("#imageFile");
    const imageSrc = imageElement?.src;
    if (imageSrc) {
      try {
        const response = await fetch(imageSrc);
        const imageBlob = await response.blob();
        const companyName = imageElement.alt || "company_logo";
        const sanitizedCompanyName = companyName.replace(/[^a-z0-9]/gi, "_").toLowerCase();
        formDataToSend.append("imageFile", imageBlob, `${sanitizedCompanyName}_logo.png`);
      } catch (error) {
        console.error("Error fetching image blob:", error);
      }
    }

      try {
         //  const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote",{
            const response = await fetch("http://127.0.0.1:8000/api/get-a-quote",{
              method: "POST",
              headers: {
                  "Content-Type":"application/json"
              },
            //   body:JSON.stringify(formData),
                 body: formDataToSend, // Send FormData directly
          });
          console.log(response)
          if(response.ok){
              setMessage('Your ticket has been raised!');
              console.log("Ticket Raised...");
  
              setTimeout(() => {
                  setMessage(''); 
                
                }, 2000);
            setFormData({
               name: '',
               email: '',
               phone: '',
               service:''
            })
          }
          
       } catch (error) {
          console.error(error)
      }
      
    };

   return (
      <div className="flex flex-row justify-center items-center">
       <img
            id="imageFile"
            style={{ width: "10%", display: "none" }}
            src={assets.quote_img}
            alt="Quote Ticket"
            />
       
         <form onSubmit={handleSubmit} action="">
            <div className=" grid gris-cols-1 gap-3 max-w-[300px] bg-[#fff]  rounded-lg shadow-xl p-4 ">
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="name" className='font-medium'>Name</label>
                  <input
                     type="text"
                     name="name"
                     value={formData.name}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
                  <h1 className='h-[10px] mt-[-10px]'>{errors.name && <span className="text-red-600 text-[10px]">{errors.name}</span>}</h1>
               </div>

               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="email" className='font-medium'>Email</label>
                  <input
                     type="email"
                     name="email"
                     value={formData.email}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
                  <h1 className='h-[10px] mt-[-10px]'>{errors.email && <span className="text-red-600 text-[10px]">{errors.email}</span>}</h1>
               </div>
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="phone" className='font-medium'>Phone</label>
                  <input
                     type="number"
                     name="phone"
                     value={formData.phone}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
                  <h1 className='h-[10px] mt-[-10px]'>{errors.phone && <span className="text-red-600 text-[10px]">{errors.phone}</span>}</h1>
               </div>
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="service" className='font-medium'>Which service do you need?</label>
                  <select name="service"
                     value={formData.service}
                     onChange={handleChange}
                     className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'
                     required >
                     <option value="">Select</option>
                     <option value="Group Health Insurance">Group Health Insurance</option>
                     <option value="WFire & Burglary Insurance">Fire & Burglary Insurance</option>
                     <option value="Office Package Policy">Office Package Policy</option>
                     <option value="Group Accidental Insurance">Group Accidental Insurance</option>
                     <option value="General Liability Insurance">General Liability Insurance</option>
                     <option value="Aviation Insurance">Aviation Insurance</option>
                     <option value="Construction Insurance">Construction Insurance</option>
                     <option value="Marine Insurance">Marine Insurance</option>
                     <option value="Cyber Security Insurance">Cyber Security Insurance</option>
                     <option value="Director's & Officers Liability Insurance">Director's & Officers Liability Insurance</option>
                     <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
                     <option value="Professional Indemnity Insurance">Professional Indemnity Insurance</option>
                     <option value="Workmen Compensation Insurance">Workmen Compensation Insurance</option>
                  </select>

                  <h1 className='h-[10px] mt-[-10px]'>{errors.service && (
                  <span className="text-red-600 text-[10px]">{errors.service}</span>
                  )}</h1>
               </div>

               {/* <img
                  id="imageFile"
                  style={{ width: "10%", display: "" }}
                  src={assets.quote_img}
                  alt="Quote Ticket"
                  /> */}

               <div className="flex flex-row justify-center items-center bg-[#61BC6D] rounded-md mt-4">
                  <button type="submit" className='p-3 rounded-md text-[#ffff] font-bold'>Talk to an Expert</button>
               </div>
            </div>
           <p className='h-[10px] mt-1'>{message && <div className="text-center text-green-600 shadow-2xl font-bold mb-4">{message}</div>}</p> 
         </form>
      </div>
   )
}

export default GetAQuote