import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import WorkmenCompensationInsuranceFaq from '../faq/WorkmenCompensationInsuranceFaq ';
import { assets } from '../../assets/assets';

const importanceOfWorkmenCompensation = [
    { title: "Employee Welfare", description: "Workplace accidents can cause significant financial and emotional distress to employees and their families. This insurance ensures they are adequately compensated." },
    { title: "Legal Compliance", description: "The Workmen’s Compensation Act mandates this policy for certain businesses. Having this insurance ensures you meet statutory obligations." },
    { title: "Protection Against Legal Claims", description: "In case of disputes or claims, the policy covers legal defense costs and compensation, reducing financial strain on employers." },
    { title: "Enhanced Workforce Morale", description: "Employees feel valued and secure, knowing they are covered against workplace risks, leading to higher morale and productivity." },
    { title: "Business Continuity", description: "By mitigating financial risks associated with workplace accidents, businesses can focus on growth and operations." }
  ];
  const coverageProvided = [
    { title: "Medical Expenses", description: "Covers the cost of medical treatment for workplace injuries or illnesses." },
    { title: "Disability Compensation", description: "Provides compensation for temporary or permanent disability, ensuring financial support for affected employees." },
    { title: "Death Benefits", description: "Offers financial compensation to the family of an employee in case of death caused by a workplace accident." },
    { title: "Legal Expenses", description: "Covers legal fees incurred by the employer in case of disputes or claims." },
    { title: "Occupational Diseases", description: "Protection against diseases caused by specific job-related hazards, such as chemical exposure or repetitive strain injuries." },
    { title: "Rehabilitation Costs", description: "Covers expenses for rehabilitation programs that help injured employees return to work." }
  ];
  const whoNeedsCoverage = [
    { title: "Construction Companies", description: "Employees in construction face high risks of accidents and injuries." },
    { title: "Manufacturing Units", description: "Factory workers exposed to machinery and chemicals need adequate protection." },
    { title: "Healthcare Providers", description: "Medical staff handling infectious diseases or hazardous materials require coverage." },
    { title: "Logistics and Warehousing", description: "Employees handling heavy goods or vehicles are prone to workplace injuries." },
    { title: "Small and Medium Enterprises (SMEs)", description: "Even small businesses with fewer employees must provide compensation for workplace-related risks." }
  ];
  const benefitsOfClearCover = [
    { title: "Comprehensive Coverage", description: "Our policies offer extensive coverage, including medical expenses, disabilities, and legal liabilities." },
    { title: "Tailored Plans", description: "2.We customize policies to meet the unique needs of your industry and workforce." },
    { title: "Affordable Premiums", description: "ClearCover ensures cost-effective solutions without compromising on coverage quality." },
    { title: "Hassle-Free Claims Process", description: "Our streamlined claims process ensures quick settlements with minimal paperwork." },
    { title: "Dedicated Support", description: "Our team of experts provides end-to-end assistance, from policy selection to claims settlement." }
  ];
  const typesOfDisabilities = [
    { title: "Temporary Total Disability (TTD)", description: "Compensation for employees temporarily unable to work due to an injury." },
    { title: "Permanent Total Disability (PTD)", description: "Financial support for employees permanently disabled and unable to continue working." },
    { title: "Temporary Partial Disability (TPD)", description: "Coverage for employees partially disabled but able to perform limited duties." },
    { title: "Permanent Partial Disability (PPD)", description: "Compensation for permanent but partial disabilities, such as loss of a limb or reduced physical ability." }
  ];
  const howPolicyWorks = [
    { title: "Accident or Incident Occurs", description: "A workplace injury or illness is reported to the employer." },
    { title: "Inform the Insurer", description: "Notify ClearCover immediately about the incident." },
    { title: "Submit Required Documents", description: "Provide relevant documents, such as medical reports, accident details, and proof of employment." },
    { title: "Assessment and Verification", description: "4.The insurer assesses the claim to ensure it aligns with the policy terms." },
    { title: "Claim Settlement", description: "Once approved, compensation is disbursed to the affected employee or their family." }
  ];
  const keyFeatures = [
    { title: "No-Fault Coverage", description: "Compensation is provided irrespective of who is at fault for the accident." },
    { title: "Worldwide Coverage", description: "ClearCover policies can be extended to cover employees working overseas." },
    { title: "Customizable Limits", description: "Policy limits can be adjusted to meet the specific needs of your workforce." },
    { title: "Fast Claim Processing", description: "Efficient systems ensure timely settlements to minimize employee and employer stress." }
  ];
  const exclusions = [
    { title: "Accident or Incident Occurs", description: "A workplace injury or illness is reported to the employer." },
    { title: "Inform the Insurer", description: "Notify ClearCover immediately about the incident." },
    { title: "Submit Required Documents", description: "Provide relevant documents, such as medical reports, accident details, and proof of employment." },
    { title: "Assessment and Verification", description: "The insurer assesses the claim to ensure it aligns with the policy terms." },
    { title: "Claim Settlement", description: "5.Once approved, compensation is disbursed to the affected employee or their family." }
  ];
  const claimProcess = [
    { title: "Notify the Insurer", description: "Report the incident to ClearCover with injury or illness details." },
    { title: "Provide Documentation", description: "Submit medical reports, accident details, and other required documents." },
    { title: "Verification", description: "The insurer evaluates the claim based on policy terms and documentation." },
    { title: "Disbursement", description: "Upon approval, the compensation is disbursed promptly." }
  ];
  const whyChooseClearCover = [
    { title: "Industry Expertise", description: "We understand the unique risks of different industries, offering tailored solutions." },
    { title: "Transparent Policies", description: "No hidden clauses or fine print – our policies are clear and straightforward." },
    { title: "Customer-Centric Approach", description: "We prioritize your needs, ensuring timely support and seamless claim experiences." },
    { title: "Competitive Pricing", description: "Our policies provide maximum coverage at affordable premiums." },
    { title: "Wide Network", description: "Access to a broad network of healthcare providers and legal support." }
  ];
                    

function WorkmanCompensationInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Workmen Compensation Insurance </h1>
    </div>
    {/* <img 
                    src={assets.workmen} 
                    alt="Workmen Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Workmen Compensation Insurance Policy  </h1>
        <p>Workmen Compensation Insurance, also known as Employers’ Liability Insurance, is a vital policy designed to protect both employers and employees in case of workplace injuries, illnesses, or fatalities. This policy ensures that employees receive adequate compensation for job-related risks while safeguarding businesses against potential legal liabilities. </p>

        <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we provide comprehensive Workmen Compensation Insurance tailored to the needs of various industries. With our solutions, businesses can foster a safe and secure work environment while ensuring compliance with legal requirements.</p>


        <h1 className=' text-lg md:text-xl font-bold'>What is Workmen Compensation Insurance Policy? </h1>
        <p>Workmen Compensation Insurance is a policy mandated by the <span className='font-bold'>Workmen’s Compensation Act, 1923</span>  in India. It provides financial compensation to employees or their families in case of injuries, illnesses, or deaths resulting from workplace accidents or occupational hazards.</p>
        <p>This insurance also covers employers against legal liabilities that may arise from such incidents, ensuring business continuity and employee welfare.</p>


        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Why is Workmen Compensation Insurance Coverage Important?</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {importanceOfWorkmenCompensation.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Coverage Provided by Workmen Compensation Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {coverageProvided.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Workmen Compensation Insurance Coverage?</h2>
                    <p>Workmen Compensation Insurance is essential for businesses across industries, especially those involving physical labor or hazardous environments, including: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whoNeedsCoverage.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of ClearCover Workmen Compensation Insurance Coverage</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {benefitsOfClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Types of Disabilities Covered</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {typesOfDisabilities.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Workmen Compensation Insurance Policy Works </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howPolicyWorks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Key Features of Workmen Compensation Insurance </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {keyFeatures.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">What is Not Covered? </h2>
                    <p>While Workmen Compensation Insurance offers extensive coverage, it excludes:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {exclusions.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Claim Process</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for Workmen Compensation Insurance?</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Workforce Today</h2>
                    <p>Prioritizing your employees’ health reflects your company’s values and fosters a culture of care and productivity. With ClearCover’s <span className='font-bold'>Group Health Insurance Policy</span>, you’re not just offering a benefit - you’re investing in the long-term success of your organization.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>Group Health Insurance</span>  quote or speak with our experts at <span className='font-bold'>+91 9133309629</span>. Ensure your workforce’s health and happiness, and watch your organization thrive!</p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs About Workmen Compensation Insurance Policy</h1>
                    <WorkmenCompensationInsuranceFaq/>
                </div>

    </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default WorkmanCompensationInsurance