import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const WorkmenCompensationInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Workmen Compensation Insurance?",
      para:"",
      answer:
        "Workmen Compensation Insurance provides financial coverage to employees or their families in case of workplace injuries, occupational illnesses, or fatalities. It also protects employers from legal liabilities arising from such incidents.",
    },
    {
      question: "Is Workmen Compensation Insurance mandatory?",
      para:"",
      answer:
        "Yes, under the Workmen’s Compensation Act, 1923, this insurance is mandatory for businesses employing workers in specified industries to ensure employee welfare and legal compliance.",
    },
    {
      question: "What does Workmen Compensation Insurance cover?",
      para:"The policy typically covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Medical expenses for workplace injuries or illnesses</li>
          <li>Compensation for temporary or permanent disabilities</li>
          <li>Death benefits to the employee’s family</li>
          <li>Legal defense costs for employers</li>
          <li>Occupational diseases caused by job-related hazards</li>
        </ul>
      ),
    },
    {
      question: "Who should purchase Workmen Compensation Insurance?",
      para:"",
      answer:
        "Any business with employees exposed to workplace risks should have this insurance. Industries such as construction, manufacturing, healthcare, logistics, and SMEs greatly benefit from this policy.",
    },
    {
      question: "Does Workmen Compensation Insurance cover contract workers?",
      para:"",
      answer:
        "Yes, the policy can cover contract workers and temporary employees if they are included in the policy schedule.",
    },
    {
      question: "How is the compensation amount determined?",
      para:"",
      answer:
        "Compensation is calculated based on the employee's wages, the extent of injury (temporary or permanent), and the nature of the incident (disability or death).",
    },
    {
      question: "What is not covered under Workmen Compensation Insurance?",
      para:"Exclusions typically include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Injuries caused by employee negligence or wilful misconduct</li>
          <li>Non-work-related accidents or illnesses</li>
          <li>Pre-existing medical conditions not aggravated by the job</li>
          <li>Claims made outside the policy period</li>
        </ul>
      ),
    },
    {
      question: "How is the premium calculated?",
      para:"Premiums depend on:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Nature of the business and associated risks</li>
          <li>Total workforce size</li>
          <li>Types of jobs performed and their hazard levels</li>
          <li>Coverage limits and add-ons</li>
        </ul>
      ),
    },
    {
      question: "What is the claim process for Workmen Compensation Insurance?",
      para:"",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify the insurer immediately about the incident.</li>
          <li>Submit necessary documents, including medical reports and accident details.</li>
          <li>The insurer assesses the claim and verifies its validity.</li>
          <li>Upon approval, compensation is disbursed promptly to the employee or their family.</li>
        </ol>
      ),
    },
    {
      question: "Why is Workmen Compensation Insurance important for businesses?",
      para:"This insurance:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Ensures employee welfare and financial security</li>
          <li>Protects businesses from costly legal claims</li>
          <li>Helps comply with statutory obligations</li>
          <li>Enhances employee morale and trust</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
             <div
               id={`faq-answer-${index}`}
               className="faq-answer mt-2 text-[#555] transition-all duration-300"
             >
               {faq.answer}
             </div>
         </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default WorkmenCompensationInsuranceFaq;
