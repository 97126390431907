import React, { useState } from "react";
import { Link } from "react-router-dom";

const Mobiledropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const insuranceItems = [
    { label: "Group Health Insurance", path: "/grouphealth" },
    { label: "Fire Insurance", path: "/fire" },
    { label: "Office Package Policy", path: "/office" },
    { label: "Group Accidental Insurance", path: "/groupaccidental" },
    { label: "General Liability Insurance", path: "/generalliability" },
    { label: "Aviation Insurance", path: "/aviation" },
    { label: "Construction Insurance", path: "/construction" },
    { label: "Marine Insurance", path: "/marine" },
    { label: "Cyber Security Insurance", path: "/cybersecurity" },
    { label: "Plant Machinery Insurance", path: "/plant" },
    { label: "Professional Indemnity Insurance", path: "/professional" },
    { label: "Workmen Compensation Insurance", path: "/workman" },
  ];

  return (
    <div className="w-full overflow-y-auto">
      <div
        className="flex justify-center items-center cursor-pointer text-[16px] text-[#333] hover:text-[#61BC6D] px-3"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        Products
      </div>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="bg-[#fefefe] border-t border-gray-300 mt-3 overflow-y-auto">
          {insuranceItems.map((item, index) => (
            <div
              key={index}
              className="border-b border-gray-300 py-4 px-3"
            >
              <Link
                to={item.path}
                className="text-[14px] font-medium text-[#555] hover:text-[#61BC6D]"
              >
                {item.label}
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Mobiledropdown;
