import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const GroupAccidentInsuranceFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Group Accident Insurance?",
      para:"",
      answer:
        "Group Accident Insurance provides financial protection to employees against unforeseen accidents. It covers accidental death, disability, medical expenses, and other related risks, ensuring employees and their families are financially secure.",
    },
    {
      question: "Who is eligible for Group Accident Insurance?",
      para:"",
      answer:
        "All employees of an organization can be covered under Group Accident Insurance. Employers can choose to include permanent, temporary, or contractual staff based on the policy's terms.",
    },
    {
      question: "What does Group Accident Insurance cover?",
      para:"The policy typically covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Accidental death</li>
          <li>Permanent total or partial disability</li>
          <li>Temporary total disability</li>
          <li>Medical expense reimbursement</li>
          <li>Funeral and transportation costs</li>
          <li>Child education benefits</li>
        </ul>
      ),
    },
    {
      question: "What are the key benefits of Group Accident Insurance for employers?",
      para:"",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Enhances employee welfare and morale</li>
          <li>Protects employees from financial stress due to accidents</li>
          <li>Demonstrates employer responsibility and care</li>
          <li>Attracts and retains talent in competitive markets</li>
        </ul>
      ),
    },
    {
      question: "Are pre-existing medical conditions covered under Group Accident Insurance?",
      answer:
        "No, Group Accident Insurance covers only injuries or conditions resulting from accidents. Pre-existing conditions unrelated to accidents are not covered.",
    },
    {
      question: "Can family members of employees be covered under the policy?",
      answer:
        "Some policies offer optional coverage for employees' family members, such as spouse and children, under an extended plan. This depends on the insurer and the employer's chosen policy.",
    },
    {
      question: "How is the premium for Group Accident Insurance calculated?",
      para:"Premiums are determined based on factors such as:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>The number of employees covered</li>
          <li>Job nature and associated risks</li>
          <li>Coverage limits and add-ons selected</li>
        </ul>
      ),
    },
    {
      question: "What is the difference between Group Accident Insurance and Group Health Insurance?",
      para:"",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>
            <strong>Group Accident Insurance:</strong> Focuses on accidents, providing benefits like death and disability coverage.
          </li>
          <li>
            <strong>Group Health Insurance:</strong> Covers illnesses, hospitalization costs, and medical treatments unrelated to accidents.
          </li>
        </ul>
      ),
    },
    {
      question: "How is the claim process handled?",
      para:"The claim process typically involves:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Informing the insurer about the accident</li>
          <li>Submitting required documents like accident reports, medical bills, and identity proofs</li>
          <li>The insurer assesses the claim and processes the payout as per policy terms</li>
        </ol>
      ),
    },
    {
      question: "Can employees retain the coverage if they leave the organization?",
      answer:
        "Group Accident Insurance is tied to employment. If an employee leaves the organization, their coverage under this policy usually ends unless portability options are provided by the insurer.",
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GroupAccidentInsuranceFaq;
