import React, { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    { question: "What is Group Health Insurance?", answer: "Group Health Insurance is a policy that covers a group of employees under a single plan. It typically includes hospitalization expenses, pre- and post-treatment care, and other medical benefits." },
    { question: "Who is eligible for coverage under this policy?", answer: "Full-time employees, their dependents (spouses, children, and in some cases, parents), and sometimes contractual or part-time staff are eligible, depending on the employer’s policy." },
    { question: "What medical expenses are covered under group health insurance?", answer: "The policy generally covers hospitalization, surgeries, daycare treatments, pre- and post-hospitalization care, maternity benefits, and sometimes outpatient consultations, depending on the plan." },
    { question: "Does the policy cover pre-existing conditions?", answer: "Yes, most group health insurance policies cover pre-existing conditions, usually after a specified waiting period or as part of the plan’s terms." },
    { question: "Can employees add family members to the policy?", answer: "Yes, most policies allow employees to add dependents, such as spouses, children, and parents, either by default or through an additional premium." },
    { question: "What is cashless hospitalization, and how does it work?", answer: "Cashless hospitalization allows employees to receive treatment at network hospitals without upfront payment. The insurer directly settles the bill with the hospital." },
    { question: "Are maternity and newborn expenses covered?", answer: "Yes, many group health insurance policies include maternity benefits and coverage for newborns from day one. This feature may vary depending on the employer’s chosen plan." },
    { question: "What are the tax benefits of group health insurance?", answer: "Employers can avail of tax benefits on the premium paid for group health insurance under the Income Tax Act. Employees’ contributions (if any) may also be eligible for deductions under Section 80D." },
    { question: "How can employees file a claim?", answer: "Employees can file a claim either through a cashless process at network hospitals or via reimbursement for treatment at non-network hospitals. ClearCover provides 24/7 support for claims processing." },
    { question: "Can additional benefits, like critical illness cover, be included?", answer: "Yes, add-ons like critical illness cover, accidental death and disability, and outpatient coverage can be included for enhanced protection, depending on the employer’s preferences." },
  ];

  return (
    <div className="">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            // onKeyDown={(e) => e.key === 'Enter' && toggleAnswer(index)}
            // tabIndex={0}
          >
            <span>{faq.question}</span>
            <span style={{ fontSize: "" }}>
              {activeIndex === index ? <FaChevronDown/> : <FaGreaterThan size={12}/>}
            </span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer mt-2 text-[#555]">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faq;
