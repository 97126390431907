import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const GeneralLiabilityFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is General Liability Insurance?",
      para:"",
      answer:
        "General Liability Insurance protects businesses against third-party claims for bodily injury, property damage, or personal and advertising injury caused by business operations.",
    },
    {
      question: "Who needs General Liability Insurance?",
      para:"",
      answer:
        "Any business that interacts with customers, clients, or the public can benefit from General Liability Insurance. This includes retailers, service providers, contractors, manufacturers, and event organizers.",
    },
    {
      question: "What does General Liability Insurance typically cover?",
      para:"The policy covers:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Third-party bodily injuries</li>
          <li>Property damage caused by business activities</li>
          <li>Personal and advertising injury (e.g., defamation or copyright infringement)</li>
          <li>Legal defense costs and settlements</li>
        </ul>
      ),
    },
    {
      question: "What is not covered under General Liability Insurance?",
      para:"Exclusions may include:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Employee injuries (covered under Workers’ Compensation Insurance)</li>
          <li>Professional errors (covered under Professional Liability Insurance)</li>
          <li>Damage to your business property</li>
          <li>Intentional or illegal acts</li>
        </ul>
      ),
    },
    {
      question: "How is the premium for General Liability Insurance determined?",
      para:"Premiums are based on factors such as:",
      answer: (
        <ul className="list-disc list-inside pl-5">
          <li>Business size and revenue</li>
          <li>Industry and associated risks</li>
          <li>Coverage limits and deductibles</li>
          <li>Claims history</li>
        </ul>
      ),
    },
    {
      question: "Can General Liability Insurance cover claims from online activities?",
      para:"",
      answer:
        "Typically, standard General Liability Insurance does not cover cyber risks. However, you can opt for a Cyber Liability Insurance add-on for protection against data breaches and cyberattacks.",
    },
    {
      question: "Is General Liability Insurance mandatory for businesses?",
      para:"",
      answer:
        "In many industries, General Liability Insurance is required to comply with legal regulations, obtain licenses, or secure contracts. Even if not mandatory, it’s strongly recommended for risk management.",
    },
    {
      question: "What happens if a claim exceeds the coverage limit?",
      para:"",
      answer:
        "If a claim exceeds the policy’s coverage limit, your business is responsible for paying the remaining amount. To avoid this, consider an Umbrella Liability Insurance policy to extend your coverage.",
    },
    {
      question: "Does General Liability Insurance cover lawsuits?",
      para:"",
      answer:
        "Yes, the policy covers legal defense costs, settlements, and judgments related to covered claims, such as bodily injury or property damage lawsuits.",
    },
    {
      question: "How can I file a claim under General Liability Insurance?",
      para:"To file a claim:",
      answer: (
        <ol className="list-decimal list-inside pl-5">
          <li>Notify the insurer immediately.</li>
          <li>Provide necessary documentation, including incident reports, photos, and legal notices.</li>
          <li>Cooperate with the claims team for assessment.</li>
          <li>Receive a settlement or resolution as per the policy terms.</li>
        </ol>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GeneralLiabilityFaq;
