import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import GroupAccidentInsuranceFaq from '../faq/GroupAccidentInsuranceFaq';
import { assets } from '../../assets/assets';

const keyFeaturesGroupAccidentInsurance = [
    { title: "Comprehensive Coverage", description: "Protection against accidental death, permanent disabilities, partial disabilities, and temporary disabilities." },
    { title: "Tailored Solutions", description: "Flexible plans that can be customized based on the organization's requirements and workforce size." },
    { title: "Worldwide Coverage", description: "Employees are covered for accidents that occur anywhere in the world." },
    { title: "No Medical Tests", description: "Employees are enrolled without undergoing medical examinations, ensuring hassle-free onboarding." },
    { title: "Affordable Premiums", description: "Cost-effective plans designed to provide extensive coverage without straining the company’s budget." },
  ];

  const coverageGroupAccidentInsurance = [
    { title: "Accidental Death", description: "Provides a lump sum amount to the nominee in the unfortunate event of the insured’s death due to an accident." },
    { title: "Permanent Total Disability (PTD)", description: "Offers financial compensation if the insured suffers a permanent disability that prevents them from working." },
    { title: "Permanent Partial Disability (PPD)", description: "Compensation for partial disabilities that result in a loss of earning capacity." },
    { title: "Temporary Total Disability (TTD)", description: "Covers loss of income due to temporary inability to work following an accident." },
    { title: "Medical Expenses Reimbursement", description: "Reimbursement of medical expenses incurred due to an accident, including hospitalization and treatment costs." },
    { title: "Accidental Hospitalization", description: "Coverage for hospital expenses resulting from accidental injuries." },
    { title: "Add-On Benefits", description: "Options like child education allowance, ambulance charges, and repatriation benefits." },
  ];

  const benefitsGroupAccidentInsurance = [
    { title: "Employee Security", description: "Ensures financial protection for employees and their families during unforeseen accidents." },
    { title: "Employer Branding", description: "Strengthens the organization's image as a caring and responsible employer." },
    { title: "Boosts Employee Morale", description: "Employees feel valued and secure, leading to increased productivity and loyalty." },
    { title: "Financial Support", description: "Provides immediate financial aid to employees and their families during challenging times." },
    { title: "Tax Benefits", description: "Premiums paid by employers may qualify for tax deductions under applicable laws." },
  ];

  const whyChooseClearCover = [
    { title: "Customizable Plans", description: "Tailored solutions to meet the specific needs of your workforce." },
    { title: "Hassle-Free Claims Process", description: "Transparent and efficient claims handling for minimal disruption." },
    { title: "Expert Assistance", description: "Dedicated support to help you manage the policy and claims seamlessly." },
    { title: "Competitive Pricing", description: "Affordable plans without compromising on coverage quality." },
    { title: "Comprehensive Coverage", description: "Extensive benefits that cover a wide range of accidental scenarios." },
  ];


  const addOnCovers = [
    { title: "Child Education Benefit", description: "Ensures continued education for children in case of an employee’s accidental death or permanent disability." },
    { title: "Family Transportation Benefit", description: "Covers transportation costs for family members in case of employee hospitalization." },
    { title: "Home Alteration and Vehicle Modification", description: "Financial aid for modifications required due to permanent disability." },
    { title: "Funeral Expenses", description: "Coverage for funeral costs in case of accidental death." },
    { title: "Outpatient Treatment", description: "Covers OPD expenses related to minor injuries caused by accidents." },
  ];
  
  const howGroupAccidentInsuranceWorks = [
    { title: "Policy Purchase", description: "Employers purchase a policy covering their employees." },
    { title: "Enrolment", description: "All eligible employees are enrolled automatically, ensuring seamless coverage." },
    { title: "Accident Coverage", description: "In case of an accident, the insured or their family can file a claim." },
    { title: "Claim Settlement", description: "ClearCover ensures quick and hassle-free claims settlement to provide immediate support." },
  ];

  const stepsToApplyGroupAccidentInsurance = [
    { title: "Assess Your Needs", description: "Determine the level of coverage suitable for your workforce." },
    { title: "Request a Quote", description: "Contact our team or use our online tools to get a customized quote." },
    { title: "Finalize the Plan", description: "Choose a plan and any add-ons that suit your organization’s requirements." },
    { title: "Policy Activation", description: "Complete the necessary formalities to activate the policy." },
  ];
   
  

function GroupAccidentalInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Group Accident Insurance</h1>
    </div>
    {/* <img 
                    src={assets.groupAccident} 
                    alt="Group Accident Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}


    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Group Accident Insurance Policy for Employees</h1>
        <p> Group Accident Insurance is a crucial component of employee benefits that provides financial protection against unforeseen accidents. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, our Group Accident Insurance policy is designed to safeguard employees and their families from the financial strain caused by accidental injuries, disabilities, or fatalities. This comprehensive plan ensures peace of mind and fosters a sense of security among employees, enhancing their overall productivity and loyalty.  </p>

        <h1 className=' text-lg md:text-xl font-bold'>What is Group Accident Insurance Policy?  </h1>
        <p>Group Accident Insurance is a policy that offers coverage to a group of individuals, typically employees of an organization, against accidental injuries, disabilities, or death. This policy is an excellent way for employers to demonstrate care for their employees while providing a safety net in times of crisis.</p>


        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Key Features of Group Accident Insurance</h1>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {keyFeaturesGroupAccidentInsurance.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Coverage Under Group Accident Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {coverageGroupAccidentInsurance.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of Group Accidental Insurance Policy for Employees</h2>
                    <p>Providing Group Health Insurance yields numerous benefits for both employers and employees: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {benefitsGroupAccidentInsurance.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover’s Group Accident Insurance?</h2>
                    <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we understand that every organization is unique. Our Group Accident Insurance plans are designed to align with your company’s values and requirements. Here’s why you should choose us:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Covers Available</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {addOnCovers.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Does Group Accident Insurance Work?  </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {howGroupAccidentInsuranceWorks.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why is Group Accident Insurance Important? </h2>
                    <p>Workplace accidents, road mishaps, and other unforeseen incidents can cause financial distress to employees and their families. A Group Accident Insurance policy provides a safety net, ensuring employees remain focused and secure, knowing they are protected against life’s uncertainties.</p>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Steps to Apply for Group Accident Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {stepsToApplyGroupAccidentInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>


                  

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Workforce Today</h2>
                    <p> <span className='font-bold'>Group Accidental Insurance Policy</span>  for Employees is more than just a benefit; it’s a commitment to your employees’ well-being. At ClearCover, we make it simple and affordable for organizations to protect their workforce from unforeseen risks. With our customized solutions and dedicated support, you can ensure your employees and their families are financially secure, fostering a strong and motivated workforce.</p>

                    <p>Ready to protect your team? <a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>group accidental insurance quote</span> or speak with our experts at <span className='font-bold'>+91 9133309629</span>. for a policy tailored to your needs!</p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs on Group Accident Insurance</h1>
                    <GroupAccidentInsuranceFaq/>
                </div>
         
        </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
    </div>
  )
}

export default GroupAccidentalInsurance