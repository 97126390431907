import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import FireAndBurglaryFaq from '../faq/FireAndBurglaryFaq';
import { assets } from '../../assets/assets';

const clearCoverFireBurglaryBenefits = [
    {
      title: "Comprehensive Protection",
      description: "Covers fire-related damages and losses caused by burglary under one policy."
    },
    {
      title: "Flexible Plans",
      description: "Customize your policy to match your business or personal requirements."
    },
    {
      title: "Quick Claims Process",
      description: "Streamlined and transparent claims management ensures timely settlements."
    },
    {
      title: "Extensive Coverage Options",
      description: "Include additional perils like natural disasters, theft-related damages, and riots."
    },
    {
      title: "Affordable Premiums",
      description: "Cost-effective pricing without compromising the quality of coverage."
    }
  ];

const fireBurglaryInsuranceFeatures = [
    {
      title: "Fire Damage Coverage",
      description: "Protection against damages caused by fire, lightning, explosions, and other related incidents."
    },
    {
      title: "Burglary and Theft Coverage",
      description: "Covers losses due to unauthorized access or forced entry."
    },
    {
      title: "Natural Calamities",
      description: "Optional coverage for floods, earthquakes, storms, and cyclones."
    },
    {
      title: "Loss of Stock or Inventory",
      description: "Financial protection for inventory or stock damaged due to fire or burglary."
    },
    {
      title: "Property Damage",
      description: "Coverage for damages to structures, machinery, fixtures, and fittings."
    },
    {
      title: "Third-Party Liability",
      description: "Coverage for legal liabilities arising from damages to third-party property."
    }
  ];
  
const fireBurglaryInsuranceBenefits = [
    {
      title: "Financial Security",
      description: "Mitigate financial losses and ensure quick recovery from damages."
    },
    {
      title: "Business Continuity",
      description: "Maintain smooth operations despite unforeseen events."
    },
    {
      title: "Comprehensive Protection",
      description: "Cover a wide range of risks under one convenient policy."
    },
    {
      title: "Peace of Mind",
      description: "Focus on your business or personal goals without worrying about potential threats."
    },
    {
      title: "Customizable Add-Ons",
      description: "Enhance your coverage with options like debris removal, rent loss compensation, and more."
    }
  ];

const fireBurglaryInsuranceNeeds = [
    {
      title: "Businesses",
      description: "Retail shops, offices, warehouses, and manufacturing units."
    },
    {
      title: "Homeowners",
      description: "Individuals seeking to protect their homes and belongings."
    },
    {
      title: "Landlords",
      description: "Property owners looking to safeguard rental properties from damages."
    },
    {
      title: "Financial Institutions",
      description: "Banks and other institutions protecting their premises and valuables."
    }
  ];

const fireBurglaryInsuranceProcess = [
    {
      title: "Policy Purchase",
      description: "Select a plan that meets your requirements and complete the formalities."
    },
    {
      title: "Risk Assessment",
      description: "The insurer evaluates risks based on property type, location, and usage."
    },
    {
      title: "Premium Payment",
      description: "Pay premiums based on your chosen coverage and add-ons."
    },
    {
      title: "Claim Filing",
      description: "In the event of a fire or burglary, file a claim by reporting the incident and submitting required documents."
    },
    {
      title: "Claim Settlement",
      description: "After verification, the insurer compensates for your losses as per the policy terms."
    }
  ];

const fireBurglaryAddOns = [
    {
      title: "Debris Removal",
      description: "Covers the cost of clearing debris after a fire."
    },
    {
      title: "Loss of Rent",
      description: "Compensation for rental income loss due to property damage."
    },
    {
      title: "Alternate Accommodation",
      description: "Reimbursement for temporary accommodation costs during repairs."
    },
    {
      title: "Machinery Breakdown",
      description: "Coverage for damages to machinery due to fire or other incidents."
    },
    {
      title: "Terrorism Coverage",
      description: "Protection against damages caused by acts of terrorism."
    },
    {
      title: "Valuable Document Protection",
      description: "Covers costs associated with the loss or damage of important documents."
    }
  ];

const whyFireBurglaryInsuranceMustHave = [
    {
      title: "Unpredictable Risks",
      description: "Fires and burglaries can occur unexpectedly, resulting in severe financial and emotional distress."
    },
    {
      title: "Regulatory Compliance",
      description: "Businesses are often required by law to have adequate insurance coverage."
    },
    {
      title: "Asset Protection",
      description: "Safeguard your property, inventory, and equipment from potential losses."
    },
    {
      title: "Affordable Investment",
      description: "Relatively small premium payments can prevent substantial financial losses."
    },
    {
      title: "Business Resilience",
      description: "Minimize downtime and ensure quicker recovery from disruptions."
    }
  ];

const getStartedWithClearCover = [
    {
      title: "Visit Our Website",
      description: "Explore our plans and learn more about our policies at ClearCover."
    },
    {
      title: "Contact Our Experts",
      description: "Speak with our team to understand the best options for your needs."
    },
    {
      title: "Share Your Requirements",
      description: "Provide details about your property, business, or personal goals."
    },
    {
      title: "Receive a Quote",
      description: "Get a personalized quote with flexible plan options."
    },
    {
      title: "Policy Issuance",
      description: "Complete the formalities and secure your coverage."
    }
  ];

const additionalPerksOfChoosingClearCover = [
    {
      title: "Experienced Professionals",
      description: "Our team brings years of expertise to deliver optimal solutions."
    },
    {
      title: "Wide Network",
      description: "Access to an extensive network of service providers and support centres."
    },
    {
      title: "Customer-Centric Approach",
      description: "Policies designed with your unique needs in mind."
    },
    {
      title: "Technology-Driven Solutions",
      description: "Advanced tools for quick policy issuance and claims management."
    },
    {
      title: "Transparent Pricing",
      description: "Clear communication with no hidden charges."
    }
  ];
  
function FireInsurance() {
    return (<>
        <div className="">
            <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
                <h1>Your Trusted Partner for</h1>
                <h1 className='text-[#61BC6D]'>Fire & Burglary Insurance </h1>
            </div>
            {/* <img 
                            src={assets.fire} 
                            alt="fire Insurance" 
                            style={{ width: "100%", height: "", objectFit: "cover" }} 
                          /> */}
            <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

                <h1 className=' text-lg md:text-xl font-bold'>Fire and Burglary Insurance Policy </h1>
                <p> Safeguarding your property and assets from unforeseen risks such as fires and burglaries is essential for individuals and businesses alike. These unpredictable events can result in significant financial losses, disrupt operations, and cause emotional distress. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we provide comprehensive <span className='font-bold'>Fire and Burglary Insurance Policy</span> designed to protect your valuable assets and ensure your peace of mind.</p>


                <h1 className=' text-lg md:text-xl font-bold'>What is Fire and Burglary Insurance Policy?</h1>
                <p> <span className='font-bold'>Fire and Burglary Insurance</span>  is a policy that offers financial protection against losses or damages caused by fire incidents and burglary attempts. This insurance provides coverage for property, equipment, inventory, and other valuable assets, enabling businesses and individuals to recover quickly from unexpected adversities.</p>

                <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Why Choose ClearCover for Fire & Burglary Insurance?</h1>
                    <p>At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a> we understand the unique needs of our customers and provide tailored solutions to protect their assets effectively. Here’s what makes ClearCover a trusted choice:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {clearCoverFireBurglaryBenefits.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Key Features of Fire and Burglary Insurance </h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {fireBurglaryInsuranceFeatures.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Benefits of Fire and Burglary Insurance</h2>
                    <p>Investing in Fire and Burglary Insurance offers numerous benefits:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {fireBurglaryInsuranceBenefits.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Who Needs Fire and Burglary Insurance?</h2>
                    <p>Fire and Burglary Insurance is a vital investment for:</p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {fireBurglaryInsuranceNeeds.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How Fire and Burglary Insurance Online Works</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {fireBurglaryInsuranceProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Add-On Covers for Enhanced Protection</h2>
                    <p>ClearCover offers a range of optional add-ons to provide extended protection: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {fireBurglaryAddOns.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Fire and Burglary Insurance is a Must-Have </h2>
                    <p>Implementing a Group Health Insurance Policy with ClearCover is simple and efficient: </p>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyFireBurglaryInsuranceMustHave.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">How to Get Started with ClearCover Fire and Burglary Insurance </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {getStartedWithClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Additional Perks of Choosing ClearCover </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {additionalPerksOfChoosingClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    
                    <h2 className="text-lg md:text-xl font-bold mt-6">Secure Your Assets Today</h2>
                    <p>Don’t leave the safety of your property and assets to chance. With ClearCover’s <span className='font-bold'>Fire and Burglary Insurance</span>, you can protect what matters most and ensure financial stability in the face of unexpected events. Whether you’re a homeowner, business owner, or landlord, our policies are designed to offer comprehensive coverage and peace of mind.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>fire & burglary insurance quote</span> or speak with our experts at <span className='font-bold'>+91 9133309629</span>Secure your future and protect your assets with ClearCover.</p>
                </div>

                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs on Fire and Burglary Insurance</h1>
                    <FireAndBurglaryFaq/>
                </div>
            </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
        </div>
       
   </> )
}

export default FireInsurance