import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const AviationFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Aviation Insurance?",
      para:"",
      answer: "Aviation Insurance provides financial protection for aircraft owners, operators, and other stakeholders against risks such as aircraft damage, passenger injuries, third-party liabilities, and legal claims related to aviation operations.",
    },
    {
      question: "Who needs Aviation Insurance?",
      para:"Aviation Insurance is essential for:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5 ">
          <li>Commercial airlines</li>
          <li>Private aircraft owners</li>
          <li>Charter operators</li>
          <li>Drone operators</li>
          <li>Flying schools</li>
          <li>Maintenance, repair, and overhaul (MRO) companies</li>
          <li>Airport and ground handling service providers</li>
        </ul>
      ),
    },
    {
      question: "What does Aviation Insurance typically cover?",
      para:"The policy covers:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5 ">
          <li>Aircraft damage (hull insurance)</li>
          <li>Passenger injury or death</li>
          <li>Third-party property damage or injury</li>
          <li>Crew member injuries or death</li>
          <li>Ground risks (when the aircraft is parked or under maintenance)</li>
          <li>War and terrorism risks (if opted as an add-on)</li>
        </ul>
      ),
    },
    {
      question: "Does Aviation Insurance cover drones?",
      para:"",
      answer: "Yes, specialized drone insurance is available under Aviation Insurance. It covers physical damage to drones, third-party liabilities, and risks related to drone operations, depending on the policy.",
    },
    {
      question: "How is the cost of Aviation Insurance determined?",
      para:"The premium for Aviation Insurance depends on several factors:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5 ">
          <li>Type, size, and value of the aircraft</li>
          <li>Purpose of use (commercial, private, cargo, etc.)</li>
          <li>Geographic area of operation</li>
          <li>Pilot and crew experience</li>
          <li>Coverage limits and deductibles</li>
        </ul>
      ),
    },
    {
      question: "What is excluded from Aviation Insurance?",
      para:"Common exclusions include:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5 ">
          <li>Wear and tear or mechanical breakdowns</li>
          <li>Illegal operations or unlicensed pilots</li>
          <li>Intentional acts or negligence</li>
          <li>War or terrorism (unless specifically included)</li>
          <li>Flights outside the agreed operational area</li>
        </ul>
      ),
    },
    {
      question: "What is Hull All-Risk Insurance?",
      para:"",
      answer: "Hull All-Risk Insurance provides comprehensive coverage for physical damage to an aircraft, whether it’s in-flight, on the ground, or in storage. It covers risks such as accidents, fire, natural disasters, and theft.",
    },
    {
      question: "Does Aviation Insurance cover passenger liabilities?",
      para:"",
      answer: "Yes, passenger liability insurance provides compensation for injuries, fatalities, or legal claims made by passengers due to an accident involving the insured aircraft.",
    },
    {
      question: "What is War and Terrorism Coverage in Aviation Insurance?",
      para:"",
      answer: "This optional coverage protects against damages or liabilities resulting from acts of war, terrorism, hijacking, or sabotage. It is not typically included in standard policies but can be added as an endorsement.",
    },
    {
      question: "How do I file a claim under Aviation Insurance?",
      para:"To file a claim:",
      answer: (
        <ol className="flex flex-col gap-2 list-disc list-inside pl-5 ">
          <li>Notify the insurer immediately after the incident.</li>
          <li>Submit required documentation, such as incident reports, photos, and repair estimates.</li>
          <li>Cooperate with the claims team for investigation and assessment.</li>
          <li>Receive settlement as per the policy terms.</li>
        </ol>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
            </div>

          )}
        </div>
      ))}
    </div>
  );
};

export default AviationFaq;
