import React, { useState, useEffect } from 'react';
import { assets } from '../../assets/assets'
import { team_cards } from '../../assets/assets';
import { FaArrowRight } from "react-icons/fa";


function AboutSewction() {

    const [lastDigit, setLastDigit] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        setLastDigit(prev => (prev + 1) % 10); // Increment the last digit and reset at 9
      }, 50); // Adjust speed as needed
      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);
   
    
  return (<>
    <div className="flex flex-col gap-20">
        <div className="py-20  flex flex-col justify-center items-center text-[22px]  bg-[#f4f7fa]">
                <h1 className='sm:text-4xl font-bold'>About <span className='text-[#61BC6D]'>ClearCover</span></h1>
                <p className='px-4 text-center text-[16px] mt-2'>Our mission is to build India’s most trusted platform for personalized health and benefits for employees and their families.</p>
                <p className='text-center text-[16px] hidden lg:block'>We aim to empower individuals by delivering tailored solutions that cater to their unique needs, ensuring their well-being and peace of mind</p>
        </div>

      
    <div className="relative overflow-hidden mt-[-25.5%]">
            <img 
                src={assets.team_group} 
                alt="Clearcover team group insurance" 
                className="object-top w-full h-auto" 
                style={{ clipPath: 'inset(30% 0 0% 0)' }}
            />
        </div>

        <div className=" px-4 max-w-[80%] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10  justify-center">
             <div className="flex flex-row items-center justify-center">
                <img src={assets.about_mackbook_img} alt="clearcover mackbook" />

             </div>
             <div className="flex flex-col ">
                <h1 className='text-2xl font-semibold'>ClearCover is transforming the insurance industry by empowering both consumers and service providers through innovative technology.</h1>
                <div className="flex flex-col gap-6 text-[xl] font-thin mt-3 ">
                    <p className=''>The ClearCover journey began in 2017 with a vision to reshape the insurance landscape. Our CEO Mr. Abhishek  recognized the need for a more efficient, tech-driven ecosystem that could meet modern insurance demands. With this commitment, ClearCover has become a trusted leader in the industry, offering innovative insurance solutions.</p>
                    <p>Today, ClearCover leads the way in providing integrated, online insurance services. We empower clients to manage and mitigate risks with cutting-edge technology, unlocking new opportunities for growth and delivering a seamless experience.</p>
                </div>
             </div>
        </div>

       
    </div>

 
    {/* #############   WHo WE ARE  ############ */}
    
    <div className="px-4 mt-20 max-w-[80%] mx-auto ">
                <div className="flex flex-row  items-center">
                    <img src={assets.about_buseness} alt="business" width="30px" />
                     <p className='text-4xl font-bold'>Who <span className='text-[#61BC6D]'> We Are?</span></p>
                </div>
                <hr className='w-[80%] mt-2  bg-gray-700 px-4' />
    </div>
   
      <div className="flex felx-col justify-center items-center py-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 bg-[#61BC6D] h-[100%]">
            <div className=" flex flex-col justify-center text-white p-8">
                <h1 className="text-3xl font-bold">Our Vision & Expertise</h1>
                <div className="text-[15px] mt-4 flex flex-col gap-4 font-semibold">
                    <p>ClearCover is a forward-thinking insurance broker, redefining the insurance experience through innovation and customer focus. We build lasting relationships with our clients and partners by offering unparalleled, technology-driven solutions and providing the most effective, personalized insurance services available.</p>
                    <p>Headquartered in Bangalore, India, ClearCover is a premier insurance broker with a global network of strategic partners across India. Our expertise in assessing, interpreting, and forecasting risks allows us to deliver tailored insurance solutions that instill confidence and security, even in challenging times.</p>
                    <p>At ClearCover Insurance, we're committed to making insurance smarter, simpler, and more customer-focused. As a leading insurance broker in India. we specialize in group insurance, along with a wide range of other insurance products tailored to meet diverse needs. Our mission is to challenge the traditional insurance model by prioritizing customer satisfaction and delivering exceptional service at competitive rates. </p>
                </div>
            </div>
            <div className="bg-yellow-200">               
               <img src={assets.team_about_who} alt="clearcover about who we are" className=' w-full h-full'/>
            </div>
        </div>
      </div>

    {/* ############# WHY ClearCover ############ */}

    <div className="px-4  max-w-[80%] mx-auto mt-20 ">
                <div className="flex flex-row gap-1 items-center">
                    <img src={assets.about_why} alt="business" width="30px" />
                     <p className=' text-3xl md:text-4xl font-bold'>Why <span className='text-[#61BC6D]'> ClearCover</span></p>
                </div>
                <hr className='w-[80%] mt-2  bg-gray-700 px-4' />

                <div className="py-4 md:p-8  mt-3 md:w-[80%] lg:w-[90%] flex flex-col gap-4 ">
                       <p>At ClearCover, we are committed to transforming the insurance experience through our dedication to innovation and customer-centricity. Here’s why our clients and partners choose us:</p>
               

                <ul className='flex flex-col gap-4 list-decimal list-inside px-4 md:px-10'>                 
                        <div className="">
                            <li className='text-xl font-semibold inline'>Customer-Centric Approach:</li>
                            <span className='text-[14px] inline'> We prioritize your needs and tailor our solutions to meet your unique requirements. Our goal is to make insurance simple, accessible, and effective for everyone.</span>
                        </div>

                        <div className="">
                            <li className='text-xl font-semibold inline'>Cutting-Edge Technology:</li>
                            <span className='text-[14px] inline'> Leveraging the latest technology, we streamline insurance processes, providing you with efficient and transparent services. Our tech-driven solutions ensure you get the best coverage with minimal hassle.</span>
                        </div>

                        <div className="">
                            <li className='text-xl font-semibold inline'>Expertise and Trust:</li>
                            <span className='text-[14px] inline'> With a team of experienced professionals, we offer deep industry knowledge and reliable advice. Our reputation is built on trust, integrity, and a track record of excellence</span>
                        </div>

                        <div className="">
                            <li className='text-xl font-semibold inline'>Global Reach, Local Expertise:</li>
                            <span className='text-[14px] inline'> Headquartered in Bangalore, India, we combine global best practices with local insights to deliver tailored insurance solutions. Our extensive network and strategic partnerships across multiple countries enhance our service capabilities.</span>
                        </div>

                        <div className="">
                            <li className='text-xl font-semibold inline'>Innovative Solutions:</li>
                            <span className='text-[14px] inline'> We challenge conventional insurance models by offering forward-thinking solutions that adapt to the evolving needs of our clients. From group insurance to personalized risk management, we provide solutions that drive your success.</span>
                        </div>    
                </ul>
            </div>
    </div>

    {/* #####################   Security ########################## Empowering Health, Securing Futures with ClearCover*/} 
    <div className="px-4 mt-10 max-w-[80%] mx-auto ">
                <div className="flex flex-row  items-center">
                    <img src={assets.about_buseness} alt="business" width="30px" />
                     <p className='text-[20px] md:text-2xl font-bold'>Empowering Health,<span className='text-[#61BC6D]'> Securing Futures</span></p>
                </div>
                <hr className='w-[80%] mt-2  bg-gray-700 px-4' />
    </div>

    <div className="py-10 max-w-[90%] md:max-w-[70%] mx-auto grid grid-cols-1 lg:grid-cols-2 justify-center items-center lg:gap-20 gap-10 px-4">

        <div className="flex flex-col gap-1 items-center justify-center">
            <h2 className='text-xl font-bold'>ClearCover Insurance: </h2>
            <h1 className='text-3xl font-bold'>Illuminating Your </h1>
            <h1 className='text-3xl font-bold'>Path to Security</h1>
        </div>

            <div className="flex flex-col gap-6 lg:gap-20 lg:w-[75%] items-center justify-center tracking-wider leading-4 md:leading-6 text-[10px] md:text-[14px]   ">
            <div className="flex flex-col gap-2">
                    <div className="flex flex-row  gap-3 items-center">
                        <img src={assets.about_vector_icon} alt="clearcover vector icon" width="40px" />
                        <span className='text-lg font-bold'>Mission</span>
                    </div>
                    <div className="">
                            <p>At ClearCover Health, we empower with accessible, transparent, and innovative health insurance. We demystify healthcare coverage complexities, providing clarity and peace of mind. Using cutting-edge technology and a commitment to simplicity, we make quality healthcare coverage clear and attainable for all.</p>
                    </div>
            </div>

            <div className="flex flex-col gap-2">
                    <div className="flex flex-row  gap-3 items-center">
                        <img src={assets.about_vector_icon} alt="clearcover vector icon" width="40px" />
                        <span className='text-lg font-bold'>Vision</span>
                    </div>
                    <div className="">
                            <p>At ClearCover Health, we envision a future with comprehensive, affordable health insurance for all. We're committed to redefining industry standards, fostering trust, and embracing innovation to shape a healthier, happier, and more secure tomorrow for our members.</p>
                    </div>
            </div>

        </div>

    </div>

    {/* ############### count section ###################### */}
     
    {/* <div className="py-10 bg-[#f4f7fa] ">
      <div className="px-4 max-w-[80%] mx-auto ">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-10 lg:gap-0 justify-center items-center ">
          <div className="flex flex-row items-center gap-2">
            <BsBuildings size={60} className='text-gray-300' />
            <div className="">
              <p className='text-[#61BC6D] text-3xl font-bold'>20{lastDigit}</p>
              <p className='text-[14px] text-gray-400 font-thin'>Corporates</p>
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <AiOutlineUserAdd size={60} className='text-gray-300' />
            <div className="">
              <p className='text-[#61BC6D] text-3xl font-bold'>154{lastDigit}</p>
              <p className='text-[14px] text-gray-400 font-thin'>Team Members</p>
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <PiGlobeHemisphereWestThin size={60} className='text-gray-300' />
            <div className="">
              <p className='text-[#61BC6D] text-3xl font-bold'>234{lastDigit}</p>
              <p className='text-[14px] text-gray-400 font-thin'>Global Partners</p>
            </div>
          </div>

          <div className="flex flex-row items-center gap-2">
            <LiaUsersSolid size={60} className='text-gray-300' />
            <div className="">
              <p className='text-[#61BC6D] text-3xl font-bold'>1L45{lastDigit}</p>
              <p className='text-[14px] text-gray-400 font-thin'>Clients</p>
            </div>
          </div>
        </div>
      </div>
    </div> */}
   

    {/* ###############   TEAM SEction ######################## */}
      
      {/* <div className=" mt-20 max-w-[80%] mx-auto ">
                <div className="flex flex-col gap-2 sm:gap-1 justify-center items-center">
                     <p className=' text-[18px] sm:text-[20px] md:text-2xl font-bold'>We are turning our <span className='text-[#61BC6D]'>vision into reality</span></p>
                     <p className='text-[13px] sm:text-[16px] text-center'>With our team's expertise, we're on a mission to revolutionize employee care across India.</p>
                </div>
                <hr className='max-w-[90%] lg:max-w-[80%] mx-auto mt-2 text-center items-center  bg-gray-700 px-4' />
      </div> */}
     {/* #####################   TEam  ########################## */} 
            {/* <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center my-8 ">
                    <div className="flex flex-row items-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center">
                            {team_cards.map((item, index) => (
                                <div key={index}
                                    className="relative w-[200px] shadow-sm overflow-hidden group">
                                  
                                        <img src={item.image} alt="" height="600px" className="w-full h-[400px]" />
                                        <div className="flex flex-col gap-2 p-3">
                                            <p className='text-[12px] font-bold'>{item.title}</p>
                                            <span className='text-[10px] font-thin'>{item.content}</span>
                                        </div>

                                        <div
                                            className="absolute bottom-0 left-0 right-0 bg-white p-3 transform translate-y-full group-hover:translate-y-0 shadow-inner shadow-green-200
                                            group-hover:bg-white text-center  transition-all duration-300 ease-in-out">
                                                <a href="" className='text-lg font-bold text-purple-950'>In</a>
                                                <p className="text-[12px] font-bold text-gray-800 group-hover:text-[#61BC6D] text-lg">
                                                {item.title}
                                                </p>
                                                <span className="text-[10px] font-thin text-gray-500 group-hover:text-black">
                                                {item.content}
                                                </span>
                                        </div>
                                </div>
                            ))}
                            <div className=" flex flex-col justify-center items-center">
                               <p className='text-[14px] font-bold'>Want to explore more about our team?</p>
                               <div className="flex flex-row items-center gap-3 text-[#61BC6D]">
                                   <a href='/team' className='text-lg font-bold'>Meet the Team</a>
                                   <FaArrowRight size={15} className='text-[#61BC6D]'/>
                               </div>
                            </div>
                        </div>
                    </div>
            </div>  */}
    
            {/* <div className="max-w-[90%] md:max-w-[75%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center my-8 ">
                    <div className="flex flex-row items-center justify-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-8 items-center">
                        {team_cards.slice(0, 7).map((item, index) => (
                                  <div key={index} className="w-[200px] ml-7 lg:ml-0 rounded-t-lg shadow-lg shadow-green-100 overflow-hidden transform transition-transform duration-200 hover:scale-105">
                                      <img src={item.image} alt="clearcover team" className="h-[250px] w-full object-cover" />
                                  </div>
                              ))}
                            <div className=" flex flex-col justify-center items-center">
                               <p className='text-[14px] font-bold'>Want to explore more about our team?</p>
                               <div className="flex flex-row items-center gap-3 text-[#61BC6D]">
                                   <a href='/team' className='text-lg font-bold'>Meet the Team</a>
                                   <FaArrowRight size={15} className='text-[#61BC6D]'/>
                               </div>
                            </div>
                        </div>
                    </div>
            </div>  */}
    


 </> )
}

export default AboutSewction
