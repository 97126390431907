import React from 'react'
import { assets, team_cards } from '../assets/assets'

function Team() {
  return (<>
 <div className="py-20  flex flex-col justify-center items-center text-[22px]  bg-[#f4f7fa]">
    <h1 className='sm:text-4xl font-bold'>Meet Our <span className='text-[#61BC6D]'>Team</span></h1>
    <p className='px-4 text-center text-[16px] mt-2'>Our mission is to build India’s most trusted platform for personalized health and benefits for employees and their families.</p>
    <p className='text-center text-[16px] hidden lg:block'>We aim to empower individuals by delivering tailored solutions that cater to their unique needs, ensuring their well-being and peace of mind</p>
 </div>



<div className="px-4  max-w-[80%] mx-auto mt-20 flex flex-col items-center justify-center ">
          
                <div className="flex flex-row gap-1 items-center">
                        <img src={assets.team_spupport} alt="business" width="30px" />
                        <p className=' text-3xl md:text-4xl font-bold'>Leadership: The Power to  <span className='text-[#61BC6D]'> Inspire and Drive Change</span></p>
                    </div>
                    <hr className='w-[80%] mt-2  bg-gray-700 px-4' />

                    <div className="py-4 md:p-8  mt-3 md:w-[80%] lg:w-[90%] flex flex-col gap-4 ">
                        <p className=''>Creating an environment where teams are motivated to excel and collaborate is key to success. At ClearCover, we leverage our expertise, experience, and network to craft tailored solutions that safeguard and propel our clients' goals forward.</p>
                        <p>In the insurance industry, strong leadership is a critical human capital priority. With factors such as regulatory shifts, complex cyber risks, and evolving customer expectations creating a volatile environment, the need for visionary leaders who can offer strategic direction is more essential than ever.</p>
                        <p>Our success reflects the strategic vision and leadership that drives ClearCover forward. We take pride in introducing our leadership team, a group of industry pioneers who consistently deliver innovation and direction, shaping the future of insurance with expertise and foresight</p>
                    </div>

    </div>

     {/* #####################   TEam  ########################## */} 
     {/* <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center my-8 ">
                    <div className="flex flex-row items-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center">
                            {team_cards.map((item, index) => (
                                <div key={index}
                                    className="relative w-[200px] shadow-sm overflow-hidden group">
                                        
                                        <img src={item.image} alt="" className="w-full h-auto" />
                                        <div className="flex flex-col gap-2 p-3">
                                            <p className='text-[12px] font-bold'>{item.title}</p>
                                            <span className='text-[10px] font-thin'>{item.content}</span>
                                        </div>

                                     
                                        <div
                                            className="absolute bottom-0 left-0 right-0 bg-white p-3 transform translate-y-full group-hover:translate-y-0 shadow-inner shadow-green-200
                                            group-hover:bg-white text-center  transition-all duration-150 ease-in-out">
                                                <a href="" className='text-lg font-bold text-purple-950 cursor-pointer'>In</a>
                                                <p className="text-[12px] font-bold text-gray-800 group-hover:text-[#61BC6D] text-lg">
                                                {item.title}
                                                </p>
                                                <span className="text-[10px] font-thin text-gray-500 group-hover:text-black">
                                                {item.content}
                                                </span>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
      </div>  */}


            <div className="max-w-[90%] md:max-w-[80%] mx-auto flex flex-col gap-2 sm:gap-6 justify-center items-center my-8 ">
                    <div className="flex flex-row items-center">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 items-center">
                        {team_cards.map((item, index) => (
                                  <div key={index} className="w-[200px] rounded-lg shadow-lg shadow-green-100 overflow-hidden transform transition-transform duration-200 hover:scale-105">
                                      <img src={item.image} alt="" className="h-[250px] w-full object-cover" />
                                  </div>
                              ))}
                        </div>
                    </div>
            </div>          


 </> )
}

export default Team