import React from 'react'
import InsuranceSlider from '../../component/InsuranceSlider'
import MarineInsuranceFaq from '../faq/MarineInsuranceFaq';
import { assets } from '../../assets/assets';

const marineInsuranceCoverage = [
    { 
      title: "Cargo Insurance", 
      description: "Protects the goods being transported." 
    },
    { 
      title: "Hull Insurance", 
      description: "Covers the vessel itself." 
    },
    { 
      title: "Freight Insurance", 
      description: "Ensures financial protection against loss of freight revenue." 
    },
    { 
      title: "Liability Insurance", 
      description: "Provides coverage for third-party liabilities." 
    },
    ];
  
 const importance=[
      {
        title: "Protection Against Uncertainties",
        description: "Shipping goods involves risks like natural disasters, theft, accidents, or damage during handling. Marine insurance mitigates these risks, ensuring that unforeseen events don’t lead to financial losses."
      },
      {
        title: "Legal Compliance",
        description: "Marine insurance is often a legal requirement for international trade. It ensures that your goods meet shipping regulations, minimizing potential disputes."
      },
      {
        title: "Customized Coverage",
        description: "Each shipment is unique, and marine insurance policies can be tailored to meet the specific requirements of your cargo, route, and mode of transportation."
      },
      {
        title: "Peace of Mind",
        description: "With comprehensive coverage, businesses and individuals can focus on their core operations, knowing their assets are protected."
      },
    ];
   const typesOfPolicies= [
      {
        title: "Voyage Policy",
        description: "Covers a specific shipment for a single trip. Ideal for one-time cargo movements."
      },
      {
        title: "Time Policy",
        description: "Provides coverage for a specified period, usually a year, and is suitable for frequent shippers or vessel owners."
      },
      {
        title: "Open Policy",
        description: "A continuous policy that covers all shipments over a period. It is highly convenient for businesses with regular cargo movements."
      },
      {
        title: "Specific Policy",
        description: "Designed for one particular shipment or vessel, offering tailored protection."
      },
      {
        title: "Mixed Policy",
        description: "Combines aspects of voyage and time policies for flexible coverage."
      },
      {
        title: "Hull Insurance Policy",
        description: "Primarily for vessel owners, covering the ship against damages due to accidents, collisions, or natural calamities."
      },
    ];
  const  keyFeatures= [
      { title: "Comprehensive Coverage", description: "From minor damages to total loss, we ensure your cargo and vessels are fully protected." },
      { title: "Customizable Policies", description: "Tailored solutions to meet specific business and cargo needs." },
      { title: "Global Network", description: "Seamless claim support across international shipping routes." },
      { title: "Quick Claim Settlement", description: "Transparent processes for hassle-free and timely claim resolutions." },
      { title: "Risk Management Support", description: "Expert guidance to minimize shipping risks and enhance safety protocols." },
    ];
   const risksCovered= [
      { title: "Natural Perils", description: "Protection against weather-related incidents like storms, floods, and cyclones." },
      { title: "Man-Made Perils", description: "Coverage for theft, piracy, and vandalism during transit." },
      { title: "Accidental Damages", description: "Compensation for damages due to collisions, sinking, or overturning." },
      { title: "Fire and Explosion", description: "Ensures financial protection in case of fire-related incidents." },
      { title: "Loss of Cargo", description: "Covers total or partial loss of goods due to any insured peril." },
      { title: "Liabilities", description: "Protection against third-party claims arising from accidental damages." },
    ];
   const whyChooseClearCover= [
      { title: "Industry Expertise", description: "With years of experience in the insurance sector, we understand the complexities of marine transportation and offer reliable solutions." },
      { title: "Customer-Centric Approach", description: "We prioritize your needs by providing customized policies, 24/7 support, and dedicated account managers." },
      { title: "Technological Edge", description: "ClearCover leverages advanced technology to provide real-time updates, digital policy management, and simplified claims processing." },
      { title: "Affordable Premiums", description: "Our cost-effective policies are designed to ensure maximum coverage without stretching your budget." },
      { title: "Global Coverage", description: "From local shipments to international trade, we provide worldwide coverage for all types of marine activities." },
    ];
   const stepsToGetInsurance= [
      { title: "Assess Your Needs", description: "Identify the type of cargo, shipping route, and potential risks involved." },
      { title: "Choose the Right Policy", description: "Select from our range of policies, including voyage, time, and open coverage." },
      { title: "Get a Quote", description: "Request a customized quote based on your specific requirements." },
      { title: "Purchase the Policy", description: "Complete the purchase process online or through our dedicated representatives." },
      { title: "Enjoy Peace of Mind", description: "With ClearCover’s comprehensive marine insurance, your cargo is in safe hands." },
    ];
   const claimProcess=[
      { title: "Notify the Insurer", description: "Inform us immediately in case of an incident." },
      { title: "Submit Documentation", description: "Provide required documents, including the insurance policy, shipping invoice, and damage report." },
      { title: "Inspection and Assessment", description: "Our team will assess the extent of the damage or loss." },
      { title: "Claim Settlement", description: "Receive prompt settlement directly to your account, ensuring minimal disruption to your operations." },
    ];
  

function MarineInsurance() {
  return (
    <div className="">
    <div className="py-20  flex flex-col justify-center items-center text-[22px] sm:text-4xl font-bold bg-[#f4f7fa]">
        <h1>Your Trusted Partner for</h1>
        <h1 className='text-[#61BC6D]'>Marine Insurance   </h1>
    </div>
    {/* <img 
                    src={assets.marine} 
                    alt="Marine Insurance" 
                    style={{ width: "100%", height: "", objectFit: "cover" }} 
                  /> */}
    <div className="py-14 max-w-[700px] mx-auto gap-8 grid grid-cols-1 text-[14px] px-4">

        <h1 className=' text-lg md:text-xl font-bold'>Marine Insurance Policy  </h1>
        <p>Marine insurance is a specialized insurance solution designed to protect goods, vessels, and associated liabilities during transit over waterways. Whether your business shipping goods internationally, a logistics provider, or an individual dealing with valuable cargo, marine insurance offers a safety net against unforeseen risks. At <a href="https://www.clearcover.in/" className='text-sky-600 font-semibold'>ClearCover</a>, we aim to provide robust marine insurance solutions that safeguard your financial interests and ensure peace of mind.  </p>


        <h1 className=' text-lg md:text-xl font-bold'>What is Marine Insurance Policy? </h1>
        <p>Marine insurance covers the loss or damage of ships, cargo, terminals, and any transport in which goods are transferred between points of origin and final destination. It is not limited to sea transit; it also extends to air and land transit, ensuring comprehensive protection for all stages of transportation.</p>

          
        <div className="flex flex-col gap-4">
                    <h1 className=' text-lg md:text-xl font-bold'>Marine insurance typically includes coverage for:</h1>                                                                                                            <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {marineInsuranceCoverage.map((benefit, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{benefit.title}: </span>
                            {benefit.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Importance of Marine Insurance</h2>
                     <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {importance.map((feature, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{feature.title}: </span>
                            {feature.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Types of Marine Insurance Policies</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {typesOfPolicies.map((advantage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                            <span className="text-[16px] text-md font-bold">{advantage.title}: </span>
                            {advantage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Key Features of ClearCover Marine Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {keyFeatures.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Risks Covered by Marine Insurance</h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {risksCovered.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Why Choose ClearCover for Marine Insurance? </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {whyChooseClearCover.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Steps to Get Marine Insurance with ClearCover </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {stepsToGetInsurance.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Claim Process </h2>
                    <ol className="flex flex-col gap-4 list-decimal list-inside ml-4">
                        {claimProcess.map((coverage, index) => (
                        <div key={index} className="flex flex-row gap-2">
                            <li className="text-[17px]"></li>
                            <div>
                                <span className="text-[16px] text-md font-bold">{coverage.title}: </span>
                                {coverage.description}
                            </div>
                        </div>
                        ))}
                    </ol>

                    <h2 className="text-lg md:text-xl font-bold mt-6">Partner with ClearCover for Seamless Marine Insurance</h2>
                    <p>At ClearCover, we understand the critical role marine insurance plays in protecting your business interests. Our tailored solutions, competitive premiums, and dedicated support make us a trusted provider for businesses across industries.</p>

                    <p><a href="https://www.clearcover.in/contact" className='text-sky-600 font-semibold'>Contact us </a> today for a <span className='font-bold'>marine insurance quote </span>or speak with our experts at <span className='font-bold'>+91 9133309629</span></p>
                </div>
                
                <div className="text-[14px]">
                    <h1 className=' text-lg md:text-xl font-bold mb-4 '>FAQs About Marine Insurance Policy</h1>
                    <MarineInsuranceFaq/>
                </div>

             </div>
            <div className=" pb-32 flex flex-col items-center justify-center mr-8 md:mr-auto">
                <InsuranceSlider />
            </div>
</div>
  )
}

export default MarineInsurance