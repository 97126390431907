import React from 'react';
import {Link} from 'react-router-dom'

const DropdownMenu = ({ items, onItemClick  }) => {
  return (
    <div style={{zIndex:'50'}}
    className="relative transition-transform duration-300 hidden lg:block">
      {/* Dropdown Menu */}
      <div style={{width:'max-content'}}
      className="absolute top-4 right-72  bg-white text-black shadow-lg rounded-lg p-4">
        {/* Dropdown Items */}
        <div className="grid grid-cols-2 gap-4">
          {items.map((item, index) => (
            <Link
            key={index}
            to={item.path}
            onClick={onItemClick}
            className="text-sm font-normal cursor-pointer px-3 py-2 border-t border-gray-200 hover:bg-gray-100 hover:scale-105 hover:text-green-500 transition-transform duration-300"
          >
            {item.label}
          </Link>
          ))}
        </div>
      </div>

      {/* Dropdown Arrow */}
      {/* <div className="absolute top-14 right-5 w-5 h-5 bg-white shadow-md transform rotate-45"></div> */}
    </div>
  );
};

export default DropdownMenu;
