import React, { useState } from "react";
import { FaChevronDown, FaGreaterThan } from "react-icons/fa";

const ConstructionFaq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What is Construction Insurance, and why is it important?",
      para:"",
      answer:
        "Construction insurance provides financial protection against risks associated with construction projects, including property damage, third-party liabilities, worker injuries, and delays. It is essential for safeguarding your project, assets, and personnel from unforeseen events that could lead to significant financial losses.",
    },
    {
      question: "What does a construction insurance policy typically cover?",
      para:"A standard construction insurance policy covers:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Contractor’s All Risk (CAR) insurance</li>
          <li>Worker’s compensation</li>
          <li>Equipment and machinery damage or theft</li>
          <li>Third-party liabilities</li>
          <li>Project delays</li>
          <li>Builder’s risk for on-site materials and structures</li>
          <li>Environmental liabilities</li>
        </ul>
      ),
    },
    {
      question: "Is construction insurance mandatory for all projects?",
      para:"",
      answer:
        "While not legally required for all construction projects, specific coverage such as worker’s compensation and third-party liability insurance may be mandatory depending on local laws and regulations. Additionally, many project owners or financiers require comprehensive insurance coverage as part of their contractual agreements.",
    },
    {
      question: "How much does construction insurance cost?",
      para:"The cost depends on various factors, including:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Project type and size</li>
          <li>Location</li>
          <li>Duration of the project</li>
          <li>Number of workers involved</li>
          <li>Chosen coverage options and add-ons</li>
        </ul>
      ),
    },
    {
      question: "Who needs construction insurance?",
      para:"Construction insurance is essential for:",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>Project owners</li>
          <li>Contractors</li>
          <li>Subcontractors</li>
          <li>Architects and engineers</li>
          <li>Equipment suppliers</li>
          <li>Any stakeholders involved in the construction project</li>
        </ul>
      ),
    },
    {
      question: "Does construction insurance cover natural disasters?",
      para:"",
      answer:
        "Yes, construction insurance can cover natural disasters such as floods, earthquakes, and storms. However, this coverage may be an optional add-on. Ensure you specify your needs when purchasing a policy.",
    },
    {
      question:
        "What is the difference between Builder’s Risk Insurance and Contractor’s All Risk Insurance?",
        para:"",
      answer: (
        <ul className="flex flex-col gap-2 list-disc list-inside pl-5">
          <li>
            <strong>Builder’s Risk Insurance:</strong> Focuses on the physical
            structures, materials, and equipment used during construction.
          </li>
          <li>
            <strong>Contractor’s All Risk Insurance:</strong> Covers physical
            damage to the construction site as well as third-party liabilities
            and accidents involving workers.
          </li>
        </ul>
      ),
    },
    {
      question: "Can subcontractors be covered under the same policy?",
      para:"",
      answer:
        "Yes, many construction insurance policies can be extended to cover subcontractors and their activities. It is advisable to clarify this when purchasing the policy to ensure all parties involved are protected.",
    },
    {
      question: "What happens if there is a delay in the project?",
      para:"",
      answer:
        "Construction insurance can include Delay in Start-Up (DSU) coverage, which compensates for financial losses caused by project delays due to insured risks, such as property damage or natural disasters.",
    },
    {
      question: "How do I file a claim for construction insurance?",
      para:"To file a claim:",
      answer: (
        <ol className="flex flex-col gap-2 list-decimal list-inside pl-5">
          <li>Notify ClearCover immediately about the incident.</li>
          <li>
            Provide all required documentation, such as project details, proof
            of loss, photos of the damage, and any relevant reports.
          </li>
          <li>
            Work with our claims team for a smooth and prompt settlement
            process.
          </li>
        </ol>
      ),
    },
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <div
          key={index}
          className="bg-[#f9f9f9] mt-2 border-b border-gray-300 py-4 px-3"
        >
          <div
            className="flex justify-between items-center text-[16px] hover:text-[#61BC6D] cursor-pointer font-bold text-[#333]"
            onClick={() => toggleAnswer(index)}
            onKeyDown={(e) => e.key === "Enter" && toggleAnswer(index)}
            tabIndex={0}
            aria-expanded={activeIndex === index}
            aria-controls={`faq-answer-${index}`}
          >
            <span>{faq.question}</span>
            <span>
              {activeIndex === index ? <FaChevronDown /> : <FaGreaterThan size={12} />}
            </span>
          </div>
          {activeIndex === index && (
            <div className="">
            <span>{faq.para}</span>
            <div
              id={`faq-answer-${index}`}
              className="faq-answer mt-2 text-[#555] transition-all duration-300"
            >
              {faq.answer}
            </div>
      </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ConstructionFaq;
